import Absolute from "../../layouts/Absolute";
import HFlex from "../../layouts/HFlex";
import "./Spinner.scss";

function Spinner() {
  return (
    <HFlex>
      <HFlex g-8>
        <HFlex width={16} rel>
          <Absolute classes={["spinner-1"]} top={"auto"} />
        </HFlex>
        <HFlex width={16} rel>
          <Absolute classes={["spinner-2"]} top={"auto"} />
        </HFlex>
        <HFlex width={16} rel>
          <Absolute classes={["spinner-3"]} top={"auto"} />
        </HFlex>
        <HFlex width={16} rel>
          <Absolute classes={["spinner-4"]} top={"auto"} />
        </HFlex>
      </HFlex>
    </HFlex>
  );
}

export default Spinner;
