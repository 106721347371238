import "swiper/css";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import { useState } from "react";
import Text from "../layouts/Text";
import SvgIcon, { SvgIconType } from "../svg";
import TextArea from "../layouts/TextArea";

function QuestionnaireTextAreaBottomSheet({
  open,
  onDismiss,
  onComplete,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: (value: string) => void;
}) {
  const [memo, setMemo] = useState("");
  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          특이사항이 있었다면 알려주세요.
        </Text>
        <SvgIcon
          size={24}
          icon={SvgIconType.Close}
          onClick={() => onDismiss()}
        />
      </HFlex>
      <VFlex p-24-trl g-16>
        <TextArea
          placeholder="예) 텍스트 입력"
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          style={{
            background: "#F8F9F8",
            borderRadius: 16,
            height: 100,
            border: "none",
            padding: "20px 16px",
          }}
        />
        <VFlex a-st p-24-tb height={112}>
          <VFlex
            f-1
            c-c
            bdr-24
            t-18-ww
            bc-pt
            onClick={() => {
              onComplete(memo);
            }}
          >
            저장
          </VFlex>
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default QuestionnaireTextAreaBottomSheet;
