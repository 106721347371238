import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import api from "../../common/api";

import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";
import TextInput from "../../layouts/TextInput";
import Image from "../../layouts/Image";
import SpeciesBottomSheet from "../SpeciesBottomSheet";

import SvgIcon, { SvgIconType } from "../../svg";

export default function LinkReservationStep({ answer: _answer }: any) {
  const { state } = useLocation();
  const { group, imp_uid, phone } = state;
  const navigate = useNavigate();
  // const [step, setStep] = useState(1);
  const [type, setType] = useState("강아지");
  const [answer, setAnswer] = useState<Record<string, any>>(_answer ?? {});
  const [speciesBottomSheetOpen, setSpeciesBottomSheetOpen] = useState(false);

  const isCompleted = useCallback(() => {
    return answer.name && answer.species && answer.age && answer.weight;
  }, [answer]);

  return (
    <VFlex
      f-1
      p-24-rl
      style={{
        maxWidth: 480,
        width: "100%",
        paddingTop: 60,
        paddingBottom: 112,
      }}
    >
      <HFlex>
        <HFlex a-c height={60}>
          <SvgIcon
            size={24}
            icon={SvgIconType.Back}
            onClick={() => navigate(-1)}
          />
        </HFlex>
        <Text m-16-tb t-24-600 l-n m-20-l>
          {/* 고객 정보 입력 {step === 1 ? '1' : '2'}/2 */}
          고객 정보 입력
        </Text>
      </HFlex>
      <Text t-14-g5 f-1>
        {/* {step === 1
          ? '예약에 필요한 정보를 입력해주세요.'
          : '예약 확정시 입력된 보호자 정보로 예약 알림톡이 발송됩니다.'} */}
        예약에 필요한 정보를 입력해주세요.
        <br />
        예약 확정시 보호자 정보로 예약 알림톡이 발송됩니다.
      </Text>

      {/* {step === 1 ? ( */}
      <VFlex>
        <Text t-18 f-1 m-32-t>
          반려동물 정보
        </Text>
        <VFlex t-14-g5 m-24-t g-4>
          <TextInput
            caption="반려동물명"
            required={true}
            placeholder="반려동물의 이름을 입력해주세요"
            value={answer.name}
            onChangeValue={(e) =>
              setAnswer({
                ...answer,
                name: e,
              })
            }
          />
        </VFlex>
        <VFlex g-4>
          <HFlex g-4 t-18-g5 f-1 m-24-t>
            <Text t-14-g5>반려동물 선택</Text>
            <Text t-12-bb>*</Text>
          </HFlex>
          <HFlex>
            <HFlex
              bdr-24
              bc-g9
              height={56}
              c-pt={type === "강아지"}
              bdw-1-pt={type === "강아지"}
              bdw-1-ww={type !== "강아지"}
              style={{
                padding: "16px 25px",
              }}
              onClick={() => {
                setType("강아지");
                setAnswer({
                  ...answer,
                  species: null,
                });
              }}
            >
              <Text t-16>강아지</Text>
            </HFlex>
            <HFlex
              m-8-l
              bdr-24
              bc-g9
              height={56}
              c-pt={type === "고양이"}
              bdw-1-pt={type === "고양이"}
              bdw-1-ww={type !== "고양이"}
              style={{
                padding: "16px 25px",
              }}
              onClick={() => {
                setType("고양이");
                setAnswer({
                  ...answer,
                  species: null,
                });
              }}
            >
              <Text t-16>고양이</Text>
            </HFlex>
          </HFlex>
        </VFlex>
        <VFlex g-4>
          <HFlex g-4 t-18-g5 f-1 m-24-t>
            <Text t-14-g5> {type === "강아지" ? "견" : "묘"}종 선택</Text>
            <Text t-12-bb>*</Text>
          </HFlex>
          <Text
            t-16-s1
            bd-t3
            p-24-rl
            height={56}
            bc-t1
            bdr-16
            style={{
              lineHeight: "56px",
              color: answer.species ? "#222" : "#BFC6C2",
            }}
            onClick={() => {
              setSpeciesBottomSheetOpen(true);
            }}
          >
            {answer.species ? (
              answer.species.name
            ) : (
              <HFlex a-c j-b>
                {type === "강아지" ? "견" : "묘"}종을 선택해주세요
                <Image src={`/image/arrow.svg`} />
              </HFlex>
            )}
          </Text>
        </VFlex>
        <VFlex t-14-g5 f-1 m-24-t g-4>
          <TextInput
            caption="나이 (살)"
            required={true}
            isNumber={true}
            placeholder="나이를 입력해주세요"
            value={answer.age}
            onChangeValue={(e) =>
              setAnswer({
                ...answer,
                age: e,
              })
            }
          />
        </VFlex>
        <VFlex t-14-g5 f-1 m-24-t g-4>
          <TextInput
            caption="몸무게 (kg 소수점 “.” 입력)"
            required={true}
            isNumber={true}
            placeholder="몸무게를 입력해주세요"
            value={answer.weight}
            onChangeValue={(e) =>
              setAnswer({
                ...answer,
                weight: e,
              })
            }
          />
        </VFlex>
      </VFlex>
      {/* ) : (
        <VFlex>
          <Text t-18 f-1 m-32-t>
            보호자 정보
          </Text>
          <VFlex t-14-g5 m-24-t g-4>
            <TextInput
              caption="보호자명"
              required={true}
              placeholder="보호자의 이름을 입력해주세요"
              value={''}
            />
          </VFlex>
          <VFlex t-14-g5 m-24-t g-4>
            <TextInput
              caption="휴대폰 번호"
              required={true}
              placeholder="휴대폰 번호를 입력해주세요"
              value={''}
            />
          </VFlex>
        </VFlex>
      )} */}

      <VFlex
        a-st
        p-24
        height={112}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
        }}
      >
        <VFlex
          f-1
          c-c
          bdr-24
          t-18-ww
          bc-pt
          style={{
            opacity: isCompleted() ? 1 : 0.5,
          }}
          onClick={async () => {
            if (!isCompleted()) {
              return alert("반려동물 정보를 모두 입력해주세요.");
            }

            // 신규 고객 등록
            const res = await api.post(
              `/ext/pet?groupId=${group.id}&impUid=${imp_uid}&cellNumber=${phone}`,
              {
                petInfo: {
                  name: answer.name,
                  species: {
                    id: answer.species.id,
                    name: answer.species.name,
                  },
                  weight: answer.weight
                    ? parseFloat(answer.weight.replaceAll(/,/g, ""))
                    : undefined,
                  age: answer.age
                    ? parseInt(answer.age!.replaceAll(/,/g, ""))
                    : undefined,
                },
              }
            );

            // 등록된 신규 고객 예약 문의하기
            navigate(`/schedule`, {
              state: {
                group: {
                  id: group?.id,
                  title: group?.title,
                },
                pet: {
                  id: res.id,
                  name: res.name,
                },
                subscriber: {
                  id: res.guardians[0].id,
                },
                type: "P_LINK_N",
              },
            });
          }}
        >
          {/* {step === 1 ? '다음' : '예약 문의하기'} */}
          예약 문의하기
        </VFlex>
      </VFlex>

      <SpeciesBottomSheet
        type={type}
        open={speciesBottomSheetOpen}
        onDismiss={() => setSpeciesBottomSheetOpen(false)}
        onComplete={(pet) => {
          setSpeciesBottomSheetOpen(false);
          setAnswer({
            ...answer,
            species: {
              id: pet.id,
              name: pet.name,
            },
          });
        }}
      />
    </VFlex>
  );
}
