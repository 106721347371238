import { BottomSheet } from "react-spring-bottom-sheet";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import Image from "../layouts/Image";

import "react-spring-bottom-sheet/dist/style.css";

import SvgIcon, { SvgIconType } from "../svg";

function ReservationDoneBottomSheet({
  open,
  onDismiss,
  onComplete,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: () => void;
}) {
  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          예약 문의를 업체에 보낼까요?
        </Text>
        <SvgIcon
          size={24}
          icon={SvgIconType.Close}
          onClick={() => onDismiss()}
        />
      </HFlex>
      <VFlex p-24-rl p-40-b>
        <Text f-1 t-16-gd>
          업체에서 문의 내역 확인 후 연락이 올거예요
        </Text>
        <VFlex a-st m-16-tb height={64}>
          <VFlex
            f-1
            c-c
            bdr-16
            t-18-ww
            bc-pt
            onClick={() => {
              onComplete();
            }}
          >
            보내기
          </VFlex>
        </VFlex>
        <HFlex g-8 j-c a-c>
          <Image width={22} height={22} src={`/image/icon_noti.png`} />
          <Text t-14-s6>문의를 보내고 난 이후에는 수정을 할 수 없어요</Text>
        </HFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default ReservationDoneBottomSheet;
