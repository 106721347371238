import { useMemo, useState } from "react";

import _ from "lodash";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import TextArea from "../../layouts/TextArea";
import VFlex from "../../layouts/VFlex";

import QuestionnaireDoneBottomSheet from "../QuestionnaireDoneBottomSheet";
import QuestionnaireTextAreaBottomSheet from "../QuestionnaireTextAreaBottomSheet";

function QuestionnaireSelect({ initialValue, options, onClick }: any) {
  const [selected, setSelected] = useState(initialValue ?? -1);

  return (
    <VFlex p-24-tb g-8>
      {options.map((option: any, i: number) => (
        <HFlex
          key={i}
          bdr-24
          p-24
          bc-g9
          height={72}
          c-pt={selected === i}
          bdw-1-pt={selected === i}
          bdw-1-ww={selected !== i}
          onClick={() => {
            if (selected === i) {
              setSelected(-1);
              onClick?.(-1);
            } else {
              setSelected(i);
              onClick?.(i);
              if (_.isObject(option)) {
                (option as any).onClick?.();
              }
            }
          }}
        >
          <Text t-16-600>
            {_.isObject(option) ? (option as any).title : option}
          </Text>
        </HFlex>
      ))}
    </VFlex>
  );
}

export default function QuestionnaireStep({
  step,
  answer: _answer,
  onComplete,
  isLastQuestion,
}: any) {
  const [answer, setAnswer] = useState<Record<string, any>>(_answer ?? {});
  const [textAreaBottomSheetOpened, setTextAreaBottomSheetOpened] =
    useState(false);
  const [endBottomSheetOpened, setEndBottomSheetOpened] = useState(false);

  const selectOptions = useMemo(() => {
    return step.questionnaireQuestions.filter((o: any) => o.type === "select");
  }, [step]);
  const hasSelectOptions = useMemo(
    () => selectOptions.length > 0,
    [selectOptions]
  );

  const textOptions = useMemo(() => {
    return step.questionnaireQuestions.filter((o: any) => o.type === "text");
  }, [step]);
  const hasTextOptions = useMemo(() => textOptions.length > 0, [textOptions]);

  const ready = useMemo(() => {
    if (!step.required) {
      return true;
    }
    return !!answer.questionId;
  }, [answer, step]);

  return (
    <VFlex>
      <VFlex m-16-t>
        <Text t-24-800-pt>Q</Text>
        <Text m-4-t t-24-600 l-n>
          {step.title}
        </Text>
        <Text m-12-t t-16-g5 l-n>
          {step.subtitle}
        </Text>
      </VFlex>
      {textOptions.map((option: any, i: number) => (
        <TextArea
          key={i}
          placeholder="예) 텍스트 입력"
          value={answer.note}
          onChange={(e) => {
            setAnswer({
              ...answer,
              questionId: option.id,
              note: e.target.value,
            });
          }}
          style={{
            background: "#F8F9F8",
            borderRadius: 16,
            height: 100,
            width: "100%",
            border: "none",
            padding: "20px 16px",
          }}
        />
      ))}
      <QuestionnaireSelect
        initialValue={selectOptions.findIndex(
          (o: any) => o.id === answer?.questionId
        )}
        options={selectOptions}
        onClick={(i: number) => {
          if (i >= 0) {
            if (selectOptions[i].textArea) {
              setTextAreaBottomSheetOpened(true);
            }
            setAnswer({
              ...answer,
              questionId: selectOptions[i].id,
              ...(answer.questionId === selectOptions[i].id
                ? {}
                : { note: undefined }),
            });
          } else {
            setAnswer({});
          }
        }}
      />
      {selectOptions.length > 0 && !_.isNil(answer.note) && (
        <VFlex p-16-tb g-16>
          <Text>어떤 특이사항이 있었나요?</Text>
          <HFlex onClick={() => setTextAreaBottomSheetOpened(true)}>
            <TextArea
              disabled={true}
              value={answer.note}
              style={{
                background: "#F8F9F8",
                borderRadius: 16,
                height: 100,
                width: "100%",
                border: "none",
                padding: "20px 16px",
              }}
            />
          </HFlex>
        </VFlex>
      )}
      <VFlex
        a-st
        p-24
        height={112}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
        }}
      >
        <VFlex
          f-1
          c-c
          bdr-24
          t-18-ww
          bc-pt
          style={{ opacity: ready ? 1 : 0.5 }}
          onClick={async () => {
            if (!ready) {
              return;
            }
            if (isLastQuestion) {
              setEndBottomSheetOpened(true);
            } else {
              if (hasSelectOptions) {
                onComplete?.(
                  answer,
                  step.questionnaireQuestionsById[answer.questionId]
                    .nextQuestions
                );
              } else if (hasTextOptions) {
                onComplete?.(answer, textOptions[0].nextQuestions);
              }
            }
          }}
        >
          다음
        </VFlex>
      </VFlex>
      <QuestionnaireTextAreaBottomSheet
        key={answer.questionId}
        open={textAreaBottomSheetOpened}
        onDismiss={() => setTextAreaBottomSheetOpened(false)}
        onComplete={(value) => {
          setTextAreaBottomSheetOpened(false);
          setAnswer({
            ...answer,
            note: value,
          });
        }}
      />
      <QuestionnaireDoneBottomSheet
        open={endBottomSheetOpened}
        onDismiss={() => setEndBottomSheetOpened(false)}
        onComplete={() => {
          setEndBottomSheetOpened(false);
          onComplete?.(answer);
        }}
      />
    </VFlex>
  );
}
