import { useState } from "react";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import Divider from "../layouts/Divider";

import { BottomSheet } from "react-spring-bottom-sheet";

import ReactCalendar from "react-calendar";
import { addDays, startOfDay } from "date-fns";
import { days } from "../common/constants";

import SvgIcon, { SvgIconType } from "../svg";
import "react-spring-bottom-sheet/dist/style.css";
import "./CalendarBottomSheet.scss";

const DAYS: Record<string, number> = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

const isHoliday = (date: Date, partner: any) => {
  // repeatType
  // 1(매주), 2(매월 첫째 주), 3(매월 둘째 주), 4(매월 셋째 주)
  // 5(매월 넷째 주), 6(매월 다섯째 주), 7(매월 여섯째 주)

  const weekOrdinal = Math.ceil(
    (date.getDate() +
      new Date(date.getFullYear(), date.getMonth(), 1).getDay() +
      1 -
      date.getDay()) /
      7
  );

  for (const holiday of partner.temporaryHolidays ?? []) {
    if (
      new Date(`${holiday.startDate}T00:00:00`).getTime() <= date.getTime() &&
      new Date(`${holiday.endDate}T00:00:00`).getTime() >= date.getTime()
    ) {
      return true;
    }
  }

  for (const holiday of partner.businessHolidays ?? []) {
    switch (holiday.repeatType) {
      case 2:
        if (weekOrdinal !== 1) {
          continue;
        } else {
          break;
        }
      case 3:
        if (weekOrdinal !== 2) {
          continue;
        } else {
          break;
        }
      case 4:
        if (weekOrdinal !== 3) {
          continue;
        } else {
          break;
        }
      case 5:
        if (weekOrdinal !== 4) {
          continue;
        } else {
          break;
        }
      case 6:
        if (weekOrdinal !== 5) {
          continue;
        } else {
          break;
        }
      case 7:
        if (weekOrdinal !== 6) {
          continue;
        } else {
          break;
        }
    }
    if (DAYS[holiday.dayOfWeek] === date.getDay()) {
      return true;
    }
  }
  return false;
};

function getNonHoliday(date: Date, partner: any) {
  while (isHoliday(date, partner)) {
    date = addDays(date, 1);
  }
  return date;
}

function CalendarBottomSheet({
  partner,
  open,
  onDismiss,
  onComplete,
}: {
  partner: any;
  open: boolean;
  onDismiss: () => void;
  onComplete: (date: Date) => void;
}) {
  const today = startOfDay(new Date());
  const minDate = partner.inquiredToday === true ? today : addDays(today, 1);
  const maxDate =
    partner.isInquiredExpirationDate === "0"
      ? undefined
      : addDays(today, partner.isInquiredExpirationDate);
  const [calendarDate, setCalendarDate] = useState(
    getNonHoliday(minDate, partner)
  );

  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={60}>
        <Text f-1 t-22-700>
          희망 날짜 선택
        </Text>
      </HFlex>
      <Divider m-24-rl />
      <ReactCalendar
        onChange={(date) => {
          setCalendarDate(date as Date);
        }}
        value={calendarDate}
        formatMonthYear={(_, date: Date) =>
          `${date.getFullYear()}년 ${date.getMonth() + 1}월`
        }
        tileContent={(props) => {
          switch (props.view) {
            case "month":
              return (
                <div
                  className={`calendar-tile ${
                    startOfDay(calendarDate).getTime() ===
                    startOfDay(props.date).getTime()
                      ? "calendar-tile-selected"
                      : ""
                  }`}
                >
                  {props.date.getDate()}
                </div>
              );
            case "year":
              return (
                <div className="calendar-tile">
                  {props.date.getMonth() + 1}월
                </div>
              );
          }
          return null;
        }}
        formatShortWeekday={(_, date: Date) => days[date.getDay()]}
        prevLabel={<SvgIcon size={24} icon={SvgIconType.CalendarLeft} />}
        prev2Label={null}
        nextLabel={<SvgIcon size={24} icon={SvgIconType.CalendarRight} />}
        next2Label={null}
        tileClassName={({ date }) => {
          return isHoliday(date, partner) && date.getTime() >= minDate.getTime()
            ? "calendar-tile-holiday"
            : "calendar-tile-disable";
        }}
        tileDisabled={({ date }) =>
          !!(isHoliday(date, partner) && date.getTime() >= minDate.getTime())
        }
        minDate={minDate}
        maxDate={maxDate}
      />
      <VFlex a-st p-24>
        <VFlex
          f-1
          c-c
          bdr-16
          t-18-ww
          bc-pt
          p-20-tb
          onClick={() => {
            onComplete(calendarDate);
          }}
        >
          저장
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default CalendarBottomSheet;
