import { createContext, PropsWithChildren, useContext, useState } from 'react';

export type EntryRoute =
  | 'reservation_history'
  | 'service_note_history'
  | 'bottom_sheet'
  | 'coparent'
  | 'banner'
  | 'inquiry'
  | 'questionnaire';

const USER_APP_LINK = process.env.REACT_APP_USER_APP_LINK_BASE;

interface AppLinkContext {
  initProperties: ({ groupId, petId }: { groupId: number; petId: number }) => void;
  openAppLink: (entryRoute?: string) => void;
}

export const appLinkContext = createContext<AppLinkContext>({
  initProperties: () => {},
  openAppLink: () => {},
});

export const AppLinkContextProvider = ({ children }: PropsWithChildren) => {
  const [groupId, setGroupId] = useState<number | undefined>(undefined);
  const [petId, setPetId] = useState<number | undefined>(undefined);

  const initProperties = ({ groupId, petId }: { groupId: number; petId: number }) => {
    setGroupId(groupId);
    setPetId(petId);
  };

  const openAppLink = (entryRoute?: string) => {
    const link =
      groupId && petId
        ? `${USER_APP_LINK}?groupId=${groupId}&petId=${petId}${
            entryRoute ? `&entryRoute=${entryRoute}` : ''
          }`
        : `${USER_APP_LINK}${entryRoute ? `?entryRoute=${entryRoute}` : ''}`;
    let newWindow = window.open();
    (newWindow as Window).location = link;
  };

  return (
    <appLinkContext.Provider value={{ initProperties, openAppLink }}>
      {children}
    </appLinkContext.Provider>
  );
};

export const useAppLinkContext = () => {
  const result = useContext(appLinkContext);

  return result;
};
