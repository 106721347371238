import axiosInstance from "../../modules/network";

// reservation 컴포넌트 내부 데이터랑 합병
export const RESERVATION_STATUS = {
  INQUIRY: "INQUIRY",
  INQUIRY_CLOSE: "INQUIRY_CLOSE",
  IN_PROGRESS: "IN_PROGRESS",
  CONFIRM: "CONFIRM",
  COMPLETE: "COMPLETE",
  USER_CANCEL: "USER_CANCEL",
  PARTNER_CANCEL: "PARTNER_CANCEL",
  NO_SHOW: "NO_SHOW",
  USER_DELETE: "USER_DELETE",
  PARTNER_DELETE: "PARTNER_DELETE",
  CONFIRM_VISIT: "CONFIRM_VISIT",
};

Object.freeze(RESERVATION_STATUS);

export const RequestFetch = () => {
  const reservation = async (revNo) => {
    try {
      const result = await axiosInstance.get(
        `/api/user/reservation/inquiry?inqUid=${revNo}`
      );
      return result.data;
    } catch (err) {
      console.error(err);
    }
  };

  const workList = async (cpId) => {
    try {
      const result = await axiosInstance.get(
        `/api/user/reservation/business-hours?cpId=${cpId}`
      );
      return result.data;
    } catch (err) {
      console.error(err);
    }
  };

  const managerList = async (cpId, currentPage) => {
    try {
      const result = await axiosInstance.get(
        `/api/user/reservation/search/manager?cpId=${cpId}&currentPage=${currentPage}&offsetSize=1&pageSize=20`
      );
      return result.data;
    } catch (err) {
      console.error(err);
    }
  };

  const serviceList = async (cpId, currentPage) => {
    try {
      const result = await axiosInstance.get(
        `/api/user/reservation/search/service?cpId=${cpId}&currentPage=${currentPage}&pageSize=20`
      );
      return result.data;
    } catch (err) {
      console.error(err);
    }
  };

  const requestReservation = async (params) => {
    try {
      const result = await axiosInstance.post(
        "/api/user/reservation/inquiry",
        params
      );
      return result.data;
    } catch (err) {
      console.error(err);
    }
  };

  const changeReservationStatus = async (revNo, status) => {
    try {
      const result = await axiosInstance.put(
        `/rest/reservation/${revNo}/status`,
        null,
        {
          params: {
            status,
          },
        }
      );
      return result.data;
    } catch (err) {
      console.error(err);
    }
  };

  return {
    reservation,
    workList,
    managerList,
    serviceList,
    requestReservation,
    changeReservationStatus,
  };
};
