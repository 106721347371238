import { useEffect, useState } from "react";

import api from "../common/api";

import { BottomSheet } from "react-spring-bottom-sheet";
import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import Image from "../layouts/Image";
import SearchInput from "../layouts/SearchInput";

import SvgIcon, { SvgIconType } from "../svg";

import "react-spring-bottom-sheet/dist/style.css";

interface PetSpecie {
  id: number;
  name: string;
}

function SpeciesBottomSheet({
  type,
  open,
  onDismiss,
  onComplete,
}: {
  type: string;
  open: boolean;
  onDismiss: () => void;
  onComplete: (value: any) => void;
}) {
  const [searchText, setSearchText] = useState<string>("");
  const [specie, setSpecie] = useState<PetSpecie[]>([]);
  const speciesId = type === "강아지" ? 4 : 5;
  const [selectedPet, setSelectedPet] = useState<PetSpecie>();

  useEffect(() => {
    (async () => {
      const result = await api.get(`/rest/species/${speciesId}/search-species`);
      setSpecie(result);
    })();
  }, [speciesId]);

  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={() => {
        onDismiss();
        setSearchText("");
      }}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          {type === "강아지" ? "견" : "묘"}종 선택
        </Text>
        <SvgIcon
          size={24}
          icon={SvgIconType.Close}
          onClick={() => {
            onDismiss();
            setSearchText("");
          }}
        />
      </HFlex>

      <VFlex p-24-trl g-24 t-16-g5>
        <SearchInput
          placeholder={`${type === "강아지" ? "견" : "묘"}종을 입력해주세요`}
          value={searchText}
          onChangeValue={(value) => {
            setSearchText(value);
          }}
        />
      </VFlex>

      <VFlex p-32-rl height={500} ovf-s style={{ marginTop: 16 }}>
        {specie
          .filter((val: PetSpecie) => {
            if (searchText === "") {
              return val;
            } else if (
              val.name.toLowerCase().includes(searchText?.toLocaleLowerCase())
            ) {
              return val;
            }
          })
          .map((pet) => {
            return (
              <HFlex
                key={pet.id}
                a-c
                j-b
                style={{
                  margin: "25px 0",
                }}
                onClick={() => {
                  setSelectedPet(pet);
                }}
              >
                <Text t-18>{pet.name}</Text>
                {selectedPet?.name === pet.name ? (
                  <Image d-28 src={`/image/check_on.svg`} />
                ) : (
                  <Image d-28 src={`/image/check_off.svg`} />
                )}
              </HFlex>
            );
          })}
      </VFlex>

      <VFlex p-24 height={112}>
        <VFlex
          f-1
          c-c
          bdr-24
          t-18-ww
          bc-pt
          onClick={() => {
            onComplete(selectedPet);
            setSearchText("");
          }}
        >
          확인
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default SpeciesBottomSheet;
