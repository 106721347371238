import { ReactElement, useState } from 'react';

import HFlex from '../layouts/HFlex';
import Image from '../layouts/Image';
import Text from '../layouts/Text';
import VFlex from '../layouts/VFlex';

function Checkbox({
  leftLabel,
  rightLabel,
  initialValue,
  onChange,
}: {
  leftLabel?: string;
  rightLabel?: string;
  initialValue?: boolean;
  onChange?: (value: boolean) => void;
}): ReactElement {
  const [value, setValue] = useState<boolean>(initialValue ?? false);

  return (
    <HFlex
      a-st
      clickable
      onClick={() => {
        setValue(!value);
        onChange?.(!value);
      }}
    >
      {leftLabel && (
        <Text f-1 m-18-r t-18-s6>
          {leftLabel}
        </Text>
      )}
      <VFlex c-c clickable>
        {value ? (
          <Image size={24} src={`/image/check_on.svg`} />
        ) : (
          <Image size={24} src={`/image/check_off.svg`} />
        )}
      </VFlex>
      {rightLabel && (
        <Text f-1 m-18-l t-18-s6>
          {rightLabel}
        </Text>
      )}
    </HFlex>
  );
}

export default Checkbox;
