import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Absolute from "../layouts/Absolute";
import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";

import { Swiper, SwiperSlide } from "swiper/react";

import SvgIcon, { SvgIconType } from "../svg";

interface Attachments {
  path: string;
  size: string;
}

export default function ServiceNotePhotos() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { content } = state;
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <VFlex
      p-24-t
      p-24-b
      bc-b
      style={{
        position: "relative",
        maxWidth: 480,
        width: "100%",
        height: "100vh",
      }}
    >
      <HFlex
        a-c
        j-c
        height={60}
        style={{
          position: "relative",
        }}
      >
        <HFlex>
          <Text t-18-600-ww>{slideIndex + 1}</Text>
          <Text t-18-600-s4>
            /{(content.attachments || content.inquiredAttachments).length}
          </Text>
        </HFlex>
        <Absolute
          left={"auto"}
          bottom={"auto"}
          top={"auto"}
          right={24}
          style={{ top: "50%", zIndex: 100000, transform: "translateY(-50%)" }}
        >
          <SvgIcon
            size={24}
            icon={SvgIconType.CloseW}
            onClick={() => navigate(-1)}
          />
        </Absolute>
      </HFlex>

      <Swiper
        style={{
          width: "100%",
          maxHeight: 720,
        }}
        slidesPerView={1}
        threshold={10}
        centeredSlides={true}
        onActiveIndexChange={(swiper) => setSlideIndex(swiper.activeIndex)}
      >
        {(content.attachments || content.inquiredAttachments).map(
          (attachment: Attachments, i: number) => (
            <SwiperSlide key={i}>
              <VFlex
                style={{
                  minHeight: 600,
                  background: `url("${attachment.path}") no-repeat center / contain`,
                }}
              ></VFlex>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </VFlex>
  );
}
