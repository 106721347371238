import Axios from "axios";

const axiosInstance = Axios.create({
    timeout: 50000,
    headers: {
        "Content-Type": "application/json",
        "Authorization": process.env.REACT_APP_AUTH_KEY
    },
    baseURL: process.env.REACT_APP_DATA_API
});


axiosInstance.interceptors.request.use(
    (config) => {
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);
axiosInstance.interceptors.response.use(
    (config) => {
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

export default axiosInstance;