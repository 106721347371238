import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import _ from "lodash";
import api from "../../common/api";

import { format } from "date-fns";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";
import Image from "../../layouts/Image";

import QuestionnaireStep from "./QuestionnaireStep";
import QuestionnaireHistoryBottomSheet from "../QuestionnaireHistoryBottomSheet";
import FloatingBanner, { FloatingKind } from "../../components/FloatingBanner";

import SvgIcon, { SvgIconType } from "../../svg";

import { useAppLinkContext } from "../../modules/useAppLink";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../../common/hooks/useAmplitudeTrack";
export function QuestionnaireHeader() {
  const navigate = useNavigate();
  return (
    <HFlex a-c height={60}>
      <SvgIcon size={24} icon={SvgIconType.Back} onClick={() => navigate(-1)} />
    </HFlex>
  );
}

export default function Questionnaire(props: any) {
  const { initProperties } = useAppLinkContext();
  const { setIdentifyObj, trackEvent } = useAmplitudeTrack();

  const { state } = useLocation();
  const {
    step,
    group,
    pet,
    subscriber,
    key,
    questionnaireId,
    sourceQuestionnaire,
    isAgreementDone,
  } = state ?? {};
  const navigate = useNavigate();
  // const [agreed1, setAgreed1] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const [agreed3, setAgreed3] = useState(false);
  const isCompleted = useCallback(() => {
    return agreed2 && agreed3;
  }, [agreed2, agreed3]);
  const [questionnaire, setQuestionnaire] = useState<any>();
  const [history, setHistory] = useState<any[]>([]);
  const [clickState, setClickState] = useState(1);
  const [bannerOpened, setBannerOpened] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    trackEvent(amplitudeEvents.questionnaireLand, { reservationNumber: key });
  }, []);

  useEffect(() => {
    (async () => {
      const history = await api.get(
        `/ext/questionnaire/history?groupId=${group.id}&petId=${pet.id}&userId=${subscriber.id}`
      );
      setHistory(history);
    })();
  }, [group, pet, subscriber]);

  useEffect(() => {
    if (group) {
      initProperties({ groupId: group.id, petId: pet.id });
      setIdentifyObj("petId", pet.id);
      setIdentifyObj("groupId", group.id);
    }
  }, [group]);

  useEffect(() => {
    (async () => {
      if (!state.answers && group) {
        if (sourceQuestionnaire) {
          const result = await api.get(
            `/ext/questionnaire/${sourceQuestionnaire.id}/result`
          );
          state.answers = Object.fromEntries(
            result.map((q: any) => [
              q.id,
              {
                questionId: q.questionnaireResults[0].questionId,
                note: q.questionnaireResults[0].note,
              },
            ])
          );
          setQuestionnaire(result);
        } else {
          const questionnaire = await api.get(
            `/ext/questionnaire/template/${questionnaireId}?groupId=${group.id}`
          );
          setQuestionnaire(questionnaire);
        }
      }
    })();
  }, [group, questionnaireId, sourceQuestionnaire, state]);

  const steps = useMemo(
    () =>
      _.sortBy(questionnaire, (q: any) => q.sortOrder).map((q: any) => ({
        ...q,
        questionnaireQuestionsById: Object.fromEntries(
          q.questionnaireQuestions.map((qq: any) => [qq.id, qq])
        ),
      })),
    [questionnaire]
  );
  const [historyBottomSheetOpened, setHistoryBottomSheetOpened] =
    useState(false);
  if (
    questionnaire &&
    sourceQuestionnaire &&
    isAgreementDone &&
    !_.isNumber(step)
  ) {
    return (
      <VFlex
        p-24-rl
        g-16
        style={{ maxWidth: 480, width: "100%", paddingBottom: 112 }}
      >
        <QuestionnaireHeader />
        <Text t-20-600>{`[${pet.name}] 미용 서비스 안전문진표`}</Text>
        <Text t-16-g6>
          {format(
            new Date(sourceQuestionnaire.actualSentDate * 1000),
            "yyyy.MM.dd"
          )}
          에 작성한 미용 서비스 안전문진표입니다.
        </Text>
        <VFlex g-8 m-32-tb>
          {steps
            .map((item: any) => ({
              title: item.title,
              answer:
                item.questionnaireQuestionsById[
                  state.answers?.[item.id]?.questionId
                ]?.title,
              note: state.answers?.[item.id]?.note,
            }))
            .map(({ title, answer, note }: any, i: number) => (
              <HFlex
                key={i}
                p-16
                g-16
                bc-g9
                bdr-24
                onClick={() => {
                  navigate("/questionnaire", {
                    state: {
                      ...state,
                      step: i,
                    },
                  });
                }}
              >
                <HFlex c-c d-28 sized>
                  <Text t-24-800-pt>Q</Text>
                </HFlex>
                <VFlex>
                  <Text t-20 l-n>
                    {title}
                  </Text>
                  {answer && (
                    <Text t-20-600-pt l-n m-8-t>
                      {answer}
                    </Text>
                  )}
                  {note && (
                    <Text m-8-t t-20-g5 l-n>
                      {note}
                    </Text>
                  )}
                </VFlex>
              </HFlex>
            ))}
        </VFlex>
        <VFlex
          a-st
          p-24
          bc-ww
          height={112}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000000,
          }}
        >
          <VFlex
            f-1
            c-c
            bdr-24
            t-18-ww
            bc-pt
            onClick={async () => {
              await api.post(`/ext/questionnaire?key=${key}`, {
                groupId: group.id,
                petId: pet.id,
                userId: subscriber.id,
                results: Object.entries(state.answers).map(
                  ([key, value]: [string, any]) => ({
                    templateId: questionnaireId,
                    contentId: parseInt(key),
                    questionId: value.questionId,
                    note: value.note,
                  })
                ),
              });
              navigate(`/reservation?key=${key}`);
            }}
          >
            사용하기
          </VFlex>
        </VFlex>
      </VFlex>
    );
  }
  if (questionnaire && isAgreementDone && _.isNumber(step)) {
    return (
      <VFlex
        p-24-rl
        style={{ maxWidth: 480, width: "100%", paddingBottom: 112 }}
      >
        <QuestionnaireHeader />
        <QuestionnaireStep
          key={step}
          step={steps[step]}
          answer={state.answers?.[steps[step].id] ?? steps[step].answers}
          isLastQuestion={step >= steps.length - 1}
          onComplete={async (answer: any, nextQuestions: string) => {
            const updatedAnswers = {
              ...state.answers,
              [steps[step].id]: answer,
            };
            if (nextQuestions) {
              trackEvent(amplitudeEvents.questionnaireFillIn, {
                reservationNumber: key,
                step,
              });
              navigate("/questionnaire", {
                state: {
                  ...state,
                  step: sourceQuestionnaire
                    ? undefined
                    : questionnaire.findIndex(
                        (q: any) => q.id === nextQuestions
                      ),
                  answers: updatedAnswers,
                },
              });
            } else {
              if (isLoading) return;

              setIsLoading(true);

              await api.post(`/ext/questionnaire?key=${key}`, {
                groupId: group.id,
                petId: pet.id,
                userId: subscriber.id,
                results: Object.entries(updatedAnswers).map(
                  ([key, value]: [string, any]) => ({
                    templateId: questionnaireId,
                    contentId: parseInt(key),
                    questionId: value.questionId,
                    note: value.note,
                  })
                ),
              });

              setIsLoading(false);

              trackEvent(amplitudeEvents.questionnaireComplete, {
                reservationNumber: key,
              });
              navigate(`/reservation?key=${key}`);
            }
          }}
        />
      </VFlex>
    );
  }
  return (
    <>
      {clickState === 1 ? (
        <VFlex
          f-1
          p-24-rl
          style={{ maxWidth: 480, width: "100%", paddingBottom: 112 }}
        >
          <QuestionnaireHeader />
          <Text m-16-tb t-24-600 l-n>
            안전 문진표를 작성하기전{"\n"}약관 및 동의서에 동의해주세요
          </Text>
          <HFlex
            m-16-tb
            p-24
            g-16
            bc-g7
            bdr-24
            bc-pp
            onClick={() => {
              setClickState(2);
            }}
          >
            <SvgIcon size={24} icon={SvgIconType.Questionnaire} />
            <Text t-16-600 f-1>
              동의는 왜 필요한가요?
            </Text>
            <SvgIcon size={24} icon={SvgIconType.ChevronRight} />
          </HFlex>
          <Text m-16-t t-18 style={{ whiteSpace: "break-spaces" }}>
            자세한 동의 내용을 확인해보세요
          </Text>
          <VFlex g-8 p-32-tb g-16>
            {/* <HFlex g-8>
              <SvgIcon
                size={24}
                icon={agreed1 ? SvgIconType.Check : SvgIconType.Uncheck}
                onClick={() => setAgreed1(!agreed1)}
              />
              <Text t-16-g5 f-1>
                [필수] 미용 서비스 이용 동의
              </Text>
              <SvgIcon size={24} icon={SvgIconType.ArrowDown} />
            </HFlex> */}
            <HFlex g-8>
              <SvgIcon
                size={24}
                icon={agreed2 ? SvgIconType.Check : SvgIconType.Uncheck}
                onClick={() => setAgreed2(!agreed2)}
              />
              <HFlex
                f-1
                g-8
                onClick={() =>
                  window.open(
                    "https://pawdly.oopy.io/bc276f67-4e08-4f6f-adc6-72bf5aaecb4b",
                    "_blank"
                  )
                }
              >
                <Text t-16-g5 f-1>
                  [필수] 포들리 이용약관 동의
                </Text>
                <SvgIcon size={24} icon={SvgIconType.ArrowDown} />
              </HFlex>
            </HFlex>
            <HFlex g-8>
              <SvgIcon
                size={24}
                icon={agreed3 ? SvgIconType.Check : SvgIconType.Uncheck}
                onClick={() => setAgreed3(!agreed3)}
              />
              <HFlex
                f-1
                g-8
                onClick={() =>
                  window.open(
                    "https://pawdly.oopy.io/75b65ca9-b6a9-4a48-8f41-fb2dd02ba1a3",
                    "_blank"
                  )
                }
              >
                <Text t-16-g5 f-1>
                  [필수] 포들리 개인정보수집 및 이용동의
                </Text>
                <SvgIcon size={24} icon={SvgIconType.ArrowDown} />
              </HFlex>
            </HFlex>
          </VFlex>
          <VFlex
            a-st
            p-24
            height={112}
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000000,
            }}
          >
            <VFlex
              f-1
              c-c
              bdr-24
              t-18-ww
              bc-pt
              style={{ opacity: isCompleted() ? 1 : 0.5 }}
              onClick={async () => {
                if (history) {
                  if (!isCompleted())
                    return alert("필수 항목을 모두 체크해주세요.");

                  if (history.length > 0) {
                    setHistoryBottomSheetOpened(true);
                  } else {
                    navigate("/questionnaire", {
                      state: {
                        ...state,
                        step: 0,
                        isAgreementDone: true,
                      },
                    });
                  }
                }
              }}
            >
              다음
            </VFlex>
          </VFlex>
          <QuestionnaireHistoryBottomSheet
            history={history}
            pet={pet}
            subscriber={subscriber}
            open={historyBottomSheetOpened}
            onDismiss={() => setHistoryBottomSheetOpened(false)}
            onComplete={(item) => {
              if (item) {
                navigate("/questionnaire", {
                  state: {
                    ...state,
                    sourceQuestionnaire: item,
                    isAgreementDone: true,
                  },
                });
              } else {
                navigate("/questionnaire", {
                  state: {
                    ...state,
                    step: 0,
                    isAgreementDone: true,
                  },
                });
              }
              setHistoryBottomSheetOpened(false);
            }}
          />
          {bannerOpened && (
            <VFlex
              a-st
              p-24
              height={128}
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1000001,
              }}
            >
              <FloatingBanner
                kind={FloatingKind.Questionnaire}
                closeBanner={() => setBannerOpened(false)}
              />
            </VFlex>
          )}
        </VFlex>
      ) : (
        <VFlex
          f-1
          p-24-rl
          style={{ maxWidth: 480, width: "100%", paddingBottom: 112 }}
        >
          <HFlex a-c height={60}>
            <SvgIcon
              size={24}
              icon={SvgIconType.Back}
              onClick={() => setClickState(1)}
            />
          </HFlex>
          <Text m-16-t m-32-b t-24-600 l-n>
            안전 문진표로 파트너에게{"\n"}반려동물의 건강을 알려주세요
          </Text>
          <VFlex f-1 g-32>
            <HFlex g-24>
              <Image width={40} height={40} src={`/image/page.png`} />
              <VFlex>
                <Text t-18-pt t-600>
                  반려동물의 건강을 알려주세요
                </Text>
                <Text t-18-g3 t-600>
                  문진표에 반려동물의 건강상태를
                  <br />
                  자세하게 알려주세요
                </Text>
              </VFlex>
            </HFlex>
            <HFlex g-24>
              <Image width={40} height={40} src={`/image/dog.png`} />
              <VFlex>
                <Text t-18-pt t-600>
                  안전한 서비스를 경험해 보세요
                </Text>
                <Text t-18-g3 t-600>
                  문진표 내용을 바탕으로 제공되는
                  <br />
                  안전한 서비스를 받아보세요
                </Text>
              </VFlex>
            </HFlex>
            <HFlex g-24>
              <Image width={40} height={40} src={`/image/heart.png`} />
              <VFlex>
                <Text t-18-pt t-600>
                  반려동물의 건강을 확인해보세요
                </Text>
                <Text t-18-g3 t-600>
                  문진표 내용을 작성하며,
                  <br />
                  반려동물의 건강상태를 직접 체크해보세요
                </Text>
              </VFlex>
            </HFlex>
          </VFlex>
        </VFlex>
      )}
    </>
  );
}
