import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import api from "../common/api";

import ServiceNoteExitBottomSheet from "./ServiceNoteExitBottomSheet";
import ServiceNotePostBottomSheet from "./ServiceNotePostBottomSheet";

import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import Image from "../layouts/Image";
import TextArea from "../layouts/TextArea";
import Checkbox from "../layouts/Checkbox";

import _ from "lodash";
import { toast } from "react-hot-toast";

import SvgIcon, { SvgIconType } from "../svg";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../common/hooks/useAmplitudeTrack";
import Spinner from "../common/Spinner/Spinner";

export interface Reviews {
  id: number;
  termId: number;
  name: string;
  taxonomy: string;
}

export interface CardColor {
  id: number;
  topBgColor: string;
  bottomBgColor: string;
}

export interface Decoration {
  id: number;
  title: string;
  type: string;
  icon: string;
  color: CardColor;
}

export default function ServiceNoteReview() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { trackEvent } = useAmplitudeTrack();
  const { key, data } = state;

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [reviews, setReviews] = useState<Reviews[]>([]);
  const [decList, setDecList] = useState<Decoration[]>([]);
  const [selectedReviews, setSelectedReviews] = useState<Reviews[]>([]);
  const [reviewMemo, setReviewMemo] = useState("");
  const [cardColor, setCardColor] = useState<CardColor>({
    id: 1,
    topBgColor: "#27BD63",
    bottomBgColor: "#EEFAF3",
  });
  const [exitBottomSheetOpened, setExitBottomSheetOpened] =
    useState<boolean>(false);
  const [postBottomSheetOpened, setPostBottomSheetOpened] =
    useState<boolean>(false);

  useEffect(() => {
    trackEvent(amplitudeEvents.serviceReviewLand, { reservationNumber: key });

    (async () => {
      const reviewList = await api.get(
        "/ext/taxonomy/BEAUTY_RESERVATION_REVIEW_TAG"
      );
      setReviews(reviewList);
      const decList = await api.get(
        "/rest/decoration?usedType=U2G_B_RESV_REVIEW&type=IMAGE"
      );
      setDecList(decList);
    })();
  }, []);

  const isCompleted = useCallback(() => {
    return selectedReviews.length > 0;
  }, [selectedReviews]);

  return loading ? (
    <VFlex
      style={{
        position: "absolute",
        top: "50%",
      }}
    >
      <Spinner />
    </VFlex>
  ) : (
    <VFlex p-24 style={{ maxWidth: 480, width: "100%", paddingBottom: 220 }}>
      {step === 1 ? (
        <>
          <HFlex a-c height={60}>
            <SvgIcon
              size={24}
              icon={SvgIconType.Back}
              onClick={() => {
                if (selectedReviews.length > 0 || reviewMemo) {
                  setExitBottomSheetOpened(true);
                } else {
                  navigate(-1);
                }
              }}
            />
          </HFlex>
          <VFlex m-42-b m-16-t>
            <Text t-24-600-s10 m-4-b l-n>
              {`${data.groupTitle}에 인사를 전해보세요`}
            </Text>
            <Text t-16-g5 l-n>
              작성해주신 내용은 포들리가 미용사에게 전달해드려요!
            </Text>
            <VFlex g-20 m-26-t>
              {reviews.map((review) => {
                return (
                  <VFlex key={review.id}>
                    <Checkbox
                      rightLabel={review.name}
                      initialValue={selectedReviews
                        .map((value) => value.id)
                        .includes(review.id)}
                      onChange={(value) => {
                        if (value) {
                          setSelectedReviews((v) => _.uniq([...v, review]));
                        } else {
                          setSelectedReviews((v) =>
                            v.filter((vv) => vv.id !== review.id)
                          );
                        }
                      }}
                    />
                  </VFlex>
                );
              })}
            </VFlex>
          </VFlex>
          <VFlex g-16>
            <Text t-24-600-s10>감사 인사를 남겨주세요</Text>
            <VFlex>
              <TextArea
                placeholder="내용을 입력해주세요."
                value={reviewMemo}
                maxLength={500}
                t-16-s8
                p-16
                bdr-16
                ovf-s
                bd-t3
                style={{
                  height: 100,
                }}
                onChange={(e) => {
                  setReviewMemo(e.target.value);
                }}
              />
            </VFlex>
          </VFlex>
        </>
      ) : (
        <>
          {step === 2 ? (
            <HFlex a-c height={60}>
              <SvgIcon
                size={24}
                icon={SvgIconType.Back}
                onClick={() => {
                  setStep(step - 1);
                }}
              />
            </HFlex>
          ) : null}
          <VFlex m-16-t>
            <VFlex>
              <VFlex
                rel
                height={195}
                style={{
                  background: `${cardColor.topBgColor}`,
                  borderRadius: "24px 24px 0 0",
                }}
              >
                <Image
                  width={223}
                  src={`/image/review/card_${cardColor.id}.png`}
                  style={{
                    position: "absolute",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    margin: "0 auto",
                  }}
                />
              </VFlex>
              <VFlex
                style={{
                  padding: "24px 24px 32px",
                  background: `${cardColor.bottomBgColor}`,
                  borderRadius: "0 0 24px 24px",
                }}
              >
                <VFlex>
                  {reviewMemo && (
                    <Text t-16 m-24-b l-n>
                      {reviewMemo}
                    </Text>
                  )}
                  <Text t-18-600 m-16-b>
                    {data.pet.name} 보호자님이 선택한 후기예요
                  </Text>
                  <VFlex g-4>
                    {selectedReviews.map((review) => {
                      return (
                        <HFlex key={review.id} g-16>
                          <Image
                            width={16}
                            src={`/image/review/check_${cardColor.id}.png`}
                          />
                          <Text>{review.name}</Text>
                        </HFlex>
                      );
                    })}
                  </VFlex>
                </VFlex>
              </VFlex>
            </VFlex>
            <VFlex g-16 m-36-t>
              {step === 3 ? (
                <></>
              ) : (
                <HFlex g-28 c-c>
                  {decList
                    .map((v) => {
                      return v.color;
                    })
                    .map((color) => {
                      return (
                        <Image
                          key={color.id}
                          d-32
                          src={
                            cardColor?.id === color.id
                              ? `/image/review/check_on${color.id}.png`
                              : `/image/review/check_off${color.id}.png`
                          }
                          onClick={() => {
                            setCardColor(color);
                          }}
                        />
                      );
                    })}
                </HFlex>
              )}
            </VFlex>
          </VFlex>
        </>
      )}

      {step === 1 ? (
        <VFlex
          a-st
          p-24
          height={112}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            margin: "0 auto",
            maxWidth: 480,
            zIndex: 1000000,
          }}
        >
          <VFlex
            f-1
            c-c
            bdr-16
            t-18-ww
            bc-pt
            p-20-tb
            style={{
              opacity: isCompleted() ? 1 : 0.5,
            }}
            onClick={async () => {
              if (!isCompleted()) {
                return toast.error("최소 1개의 인사말을 선택해주세요.");
              }
              setStep(step + 1);
            }}
          >
            다음
          </VFlex>
        </VFlex>
      ) : step === 2 ? (
        <VFlex
          a-st
          p-24
          height={112}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            margin: "0 auto",
            maxWidth: 480,
            zIndex: 1000000,
          }}
        >
          <VFlex
            f-1
            c-c
            bdr-16
            t-18-ww
            bc-pt
            p-20-tb
            onClick={() => {
              setPostBottomSheetOpened(true);
            }}
          >
            다음
          </VFlex>
        </VFlex>
      ) : (
        <VFlex
          a-st
          p-24
          height={112}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            margin: "0 auto",
            maxWidth: 480,
            zIndex: 1000000,
          }}
        >
          <VFlex
            f-1
            c-c
            bdr-16
            t-18-ww
            bc-pt
            p-20-tb
            onClick={() => {
              navigate(`/servicenote?revNo=${key}`, {
                state: null,
              });
            }}
          >
            확인
          </VFlex>
        </VFlex>
      )}

      <ServiceNoteExitBottomSheet
        open={exitBottomSheetOpened}
        onDismiss={() => setExitBottomSheetOpened(false)}
        onComplete={() => {
          setExitBottomSheetOpened(false);
          navigate(-1);
        }}
      />

      <ServiceNotePostBottomSheet
        open={postBottomSheetOpened}
        onDismiss={() => setPostBottomSheetOpened(false)}
        onComplete={async () => {
          setPostBottomSheetOpened(false);

          const formData = new FormData();
          formData.append("key", key);
          formData.append("type", "U2G_B_RESV");
          formData.append("content", reviewMemo);
          _.flatMap(selectedReviews).forEach((v, index) => {
            formData.append(`tags[${index}].id`, `${v.id}`);
          });
          formData.append("decoration.id", `${cardColor.id + 4}`);

          await api
            .post(`/ext/review`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              setLoading(true);
              toast.success("감사 인사가 전달되었어요!");
              setStep(step + 1);
              setLoading(false);
            })
            .catch((e) => {
              toast.error("이미 작성한 감사인사 입니다.");
            })
            .finally(() => {
              navigate(`/servicenote?revNo=${key}`, {
                state: null,
              });
            });

          trackEvent(amplitudeEvents.serviceReviewComplete, {
            reservationNumber: key,
          });
        }}
      />
    </VFlex>
  );
}
