import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import api from "../common/api";
import { Cancellation, RefundPolicy } from "../common/types";
import { numberWithCommas } from "../common/utils/format";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../common/hooks/useAmplitudeTrack";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import TextArea from "../layouts/TextArea";

import SvgIcon, { SvgIconType } from "../svg";

import toast from "react-hot-toast";
import { BottomSheet } from "react-spring-bottom-sheet";

import "react-spring-bottom-sheet/dist/style.css";

function CancelBottomSheet({
  type,
  open,
  onDismiss,
  onComplete,
}: {
  type: string;
  open: boolean;
  onDismiss: () => void;
  onComplete: () => void;
}) {
  const { trackEvent } = useAmplitudeTrack();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key") || searchParams.get("revNo");
  const [moreInfoVisible, setMoreInfoVisible] = useState(false);
  const [cancelReasonBottomSheetOpened, setCancelReasonBottomSheetOpened] =
    useState(false);
  const [cancellation, setCancellation] = useState<Cancellation>();
  const [refundPolicy, setRefundPolicy] = useState<RefundPolicy[]>();
  const [refundablePolicies, setRefundablePolicies] = useState<
    [
      {
        rate: number;
        refundAmount: number;
        refundRate: string;
        title: string;
      }
    ]
  >();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(
          `/ext/payment/cancellation/condition?key=${key}`
        );
        setCancellation(res);
        setRefundPolicy(res.refundPolicies);
        setRefundablePolicies(res.refundablePolicies);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleCancelReload = async () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  return (
    <>
      <BottomSheet
        className="bottomsheet"
        open={open}
        initialFocusRef={false}
        expandOnContentDrag={true}
        onDismiss={onDismiss}
      >
        <HFlex p-24-rl p-8-tb>
          <Text f-1 t-22-600>
            예약을 취소하시겠어요?
          </Text>
        </HFlex>

        <VFlex p-24-rl>
          <VFlex>
            <HFlex
              p-8-tb
              a-c
              j-b
              g-16
              onClick={() => {
                setMoreInfoVisible((prev) => !prev);
              }}
            >
              <Text t-16-600-s10>취소/환불 규정 안내</Text>
              <SvgIcon
                size={24}
                icon={moreInfoVisible ? SvgIconType.ArrUp : SvgIconType.ArrDown}
              />
            </HFlex>
            {moreInfoVisible && (
              <VFlex p-16-tb>
                <VFlex bd-t-t5 bd-b-t5>
                  {refundPolicy?.map((refund, i) => {
                    const parts = refund.refundRate.split(
                      new RegExp(`(${refund.rate}%)`)
                    );
                    return (
                      <HFlex
                        a-c
                        bd-b-t5={i !== refundPolicy.length - 1}
                        key={refund.period}
                      >
                        <VFlex f-1 p-12-tb p-16-rl bc-t2 bd-r-t5 t-14-600-s7>
                          {refund.cancelDate}
                        </VFlex>
                        <HFlex f-1 p-12-tb p-16-rl g-4>
                          {parts.map((part, index) => (
                            <VFlex
                              t-14-600-rd1={part === `${refund.rate}%`}
                              t-14-400-s4={part !== `${refund.rate}%`}
                              key={index}
                            >
                              {part}
                            </VFlex>
                          ))}
                        </HFlex>
                      </HFlex>
                    );
                  })}
                </VFlex>
              </VFlex>
            )}
            <VFlex g-10>
              <HFlex a-c p-16 g-12 bc-t1 bd-t6 bdr-16>
                <SvgIcon size={22} icon={SvgIconType.Info} />
                <Text t-14-s4>
                  취소/환불 규정에 따라
                  <Text c-rd1>
                    {` ${
                      refundablePolicies && refundablePolicies[0].rate === 0
                        ? "환불이 불가"
                        : `${
                            refundablePolicies && refundablePolicies[0].rate
                          }% 환불`
                    }`}
                  </Text>
                  {refundablePolicies && refundablePolicies[0].rate === 0
                    ? "합니다."
                    : "됩니다."}
                </Text>
              </HFlex>
              <VFlex p-8-tb g-10>
                <HFlex p-16-b a-c j-b bd-b-t4>
                  <Text t-16-600-s8>결제 금액</Text>
                  <Text t-18-600-s8>
                    {numberWithCommas(cancellation?.actualAmount ?? 0)}원
                  </Text>
                </HFlex>
                <HFlex a-c j-b>
                  <Text t-16-600-s8>환불 금액</Text>
                  <Text t-18-600-rd1>
                    {numberWithCommas(
                      (refundablePolicies &&
                        refundablePolicies[0].refundAmount) ??
                        0
                    )}
                    원
                  </Text>
                </HFlex>
              </VFlex>
            </VFlex>
          </VFlex>
          <HFlex p-16-tb g-8>
            <VFlex
              f-1
              p-16-tb
              p-24-rl
              c-c
              bdr-16
              t-18-600-s8
              bc-t2
              clickable
              onClick={() => {
                onDismiss();
                setMoreInfoVisible(false);
              }}
            >
              닫기
            </VFlex>
            <VFlex
              f-1
              p-16-tb
              p-24-rl
              c-c
              bdr-16
              t-18-600-ww
              bc-rd1
              clickable
              onClick={async () => {
                await onDismiss();
                await setCancelReasonBottomSheetOpened(true);

                trackEvent(amplitudeEvents.cancleRes, {
                  reservationNumber: key,
                });
              }}
            >
              예약 취소
            </VFlex>
          </HFlex>
        </VFlex>
      </BottomSheet>

      <CancelReasonBottomSheet
        refundResult={refundablePolicies && refundablePolicies[0]}
        open={cancelReasonBottomSheetOpened}
        onDismiss={() => setCancelReasonBottomSheetOpened(false)}
        onComplete={async ({
          rate,
          amount,
          reason,
        }: {
          rate: number;
          amount: number;
          reason: string;
        }) => {
          await api.post(`/ext/payment/cancellation?key=${key}`, {
            rate,
            amount,
            reason,
          });
          await setCancelReasonBottomSheetOpened(false);
          await handleCancelReload();
          toast.success("예약이 취소되었습니다.");
        }}
      />
    </>
  );
}

export default CancelBottomSheet;

const CancelReasonBottomSheet = ({
  refundResult,
  open,
  onDismiss,
  onComplete,
}: {
  refundResult: any;
  open: boolean;
  onDismiss: () => void;
  onComplete: ({
    rate,
    amount,
    reason,
  }: {
    rate: number;
    amount: number;
    reason: string;
  }) => void;
}) => {
  const [urgentSchedule, setUrgentSchedule] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  const [userInput, setUserInput] = useState(false);
  const [userInputValue, setUserInputValue] = useState("");

  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex p-24-rl p-8-tb>
        <Text f-1 t-22-600>
          취소 사유를 알려주세요
        </Text>
      </HFlex>

      <VFlex p-24-rl>
        <VFlex>
          <HFlex
            p-16-tb
            a-c
            j-b
            g-16
            clickable
            onClick={() => {
              setUrgentSchedule((prev) => {
                if (!prev) {
                  setChangeDate(false);
                  setUserInput(false);
                  setUserInputValue("");
                }
                return !prev;
              });
            }}
          >
            <Text t-18-600-s7>급한 일정이 생겼어요</Text>
            <SvgIcon
              size={24}
              icon={
                urgentSchedule
                  ? SvgIconType.CheckCircle
                  : SvgIconType.UncheckboxCircle
              }
            />
          </HFlex>
          <HFlex
            p-16-tb
            a-c
            j-b
            g-16
            clickable
            onClick={() => {
              setChangeDate((prev) => {
                if (!prev) {
                  setUrgentSchedule(false);
                  setUserInput(false);
                  setUserInputValue("");
                }
                return !prev;
              });
            }}
          >
            <Text t-18-600-s7>다른 날짜나 시간에 예약하고 싶어요</Text>
            <SvgIcon
              size={24}
              icon={
                changeDate
                  ? SvgIconType.CheckCircle
                  : SvgIconType.UncheckboxCircle
              }
            />
          </HFlex>
          <VFlex>
            <HFlex
              p-16-tb
              a-c
              j-b
              g-16
              clickable
              onClick={() => {
                setUserInput((prev) => {
                  if (!prev) {
                    setUrgentSchedule(false);
                    setChangeDate(false);
                  }
                  return !prev;
                });
              }}
            >
              <Text t-18-600-s7>직접 입력</Text>
              <SvgIcon
                size={24}
                icon={
                  userInput
                    ? SvgIconType.CheckCircle
                    : SvgIconType.UncheckboxCircle
                }
              />
            </HFlex>
            {userInput && (
              <TextArea
                placeholder="취소 사유를 알려주세요"
                value={userInputValue}
                onChangeValue={(v) => {
                  if (v?.length || 0 <= 800) setUserInputValue(v as string);
                }}
                height={120}
                maxLength={800}
              />
            )}
          </VFlex>
        </VFlex>
        <HFlex p-16-tb g-8>
          <VFlex
            f-1
            p-16-tb
            p-24-rl
            c-c
            bdr-16
            t-18-600-s8
            bc-t2
            clickable
            onClick={() => {
              onDismiss();
              setUrgentSchedule(false);
              setChangeDate(false);
              setUserInput(false);
            }}
          >
            닫기
          </VFlex>
          <VFlex
            f-1
            p-16-tb
            p-24-rl
            c-c
            bdr-16
            t-18-600-ww
            bc-rd1
            style={{
              opacity:
                urgentSchedule || changeDate || (userInput && userInputValue)
                  ? 1
                  : 0.5,
            }}
            clickable
            onClick={async () => {
              if (
                urgentSchedule ||
                changeDate ||
                (userInput && userInputValue)
              ) {
                onComplete({
                  rate: refundResult.rate,
                  amount: refundResult.refundAmount,
                  reason: userInputValue
                    ? userInputValue
                    : urgentSchedule
                    ? "급한 일정이 생겼어요"
                    : "다른 날짜나 시간에 예약하고 싶어요",
                });
              } else {
                return;
              }
            }}
          >
            선택 완료
          </VFlex>
        </HFlex>
      </VFlex>
    </BottomSheet>
  );
};
