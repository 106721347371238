import { BottomSheet } from "react-spring-bottom-sheet";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import SvgIcon, { SvgIconType } from "../svg";

import "react-spring-bottom-sheet/dist/style.css";

function PhoneNumberBottomSheet({
  number,
  open,
  onDismiss,
  onComplete,
}: {
  number: string;
  open: boolean;
  onDismiss: () => void;
  onComplete: (item?: any) => void;
}) {
  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-700>
          휴대폰 번호 확인
        </Text>
        <SvgIcon
          size={24}
          icon={SvgIconType.Close}
          onClick={() => onDismiss()}
        />
      </HFlex>

      <VFlex p-24-trl>
        <Text t-18 c-pt>
          {number}
        </Text>
        <Text t-18 c-gd>
          입력한 번호가 맞나요?
        </Text>
        <VFlex a-st p-24-tb height={112}>
          <VFlex
            f-1
            c-c
            bdr-24
            t-18-ww
            bc-pt
            onClick={() => {
              onComplete?.();
            }}
          >
            확인
          </VFlex>
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default PhoneNumberBottomSheet;
