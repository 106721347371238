import { useEffect, useRef, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import api from "../../common/api";
import {
  CardCompanies,
  Group,
  Installments,
  Invoices,
  PaymentInfomation,
  RefundPolicy,
  Reservation,
} from "../../common/types";
import { numberWithCommas } from "../../common/utils/format";
import { statusString } from "../../common/constants";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../../common/hooks/useAmplitudeTrack";
import { useAppLinkContext } from "../../modules/useAppLink";

import Timer from "../../components/Timer";
import InvoiceCardBottomSheet from "../InvoiceCardBottomSheet";
import InvoiceInstallmentBottomSheet from "../InvoiceInstallmentBottomSheet";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Divider from "../../layouts/Divider";
import Image from "../../layouts/Image";

import _ from "lodash";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

import SvgIcon, { SvgIconType } from "../../svg";

export default function Invoice() {
  const { openAppLink } = useAppLinkContext();
  const { trackEvent } = useAmplitudeTrack();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key") || searchParams.get("revNo");

  const [group, setGroup] = useState<Group>();
  const [reservation, setReservation] = useState<Reservation>();
  const [invoice, setInvoice] = useState<Invoices>();
  const [paymentInfomation, setPaymentInfomation] =
    useState<PaymentInfomation>();
  const [paid, setPaid] = useState(false);
  const [cardCompanies, setCardCompanies] = useState<CardCompanies[]>([]);
  const [installments, setInstallments] = useState<Installments[]>([]);
  const [refundPolicy, setRefundPolicy] = useState<RefundPolicy[]>([]);

  const [infoVisible, setInfoVisible] = useState(true);
  const [serviceVisible, setServiceVisible] = useState(true);
  const [cardVisible, setCardVisible] = useState(false);
  const [cardName, setCardName] = useState("");
  const [cardCode, setCardCode] = useState("");
  const [installment, setInstallment] = useState("");
  const [cardBottomSheetOpened, setCardBottomSheetOpened] = useState(false);
  const [installmentBottomSheetOpened, setInstallmentBottomSheetOpened] =
    useState(false);

  const [allAgreed, setAllAgreed] = useState(false);
  const [agreementStates, setAgreementStates] = useState({
    refundPolicy: false,
    privacyPolicy: false,
    thirdPartyConsent: false,
  });

  useEffect(() => {
    const allChecked = Object.values(agreementStates).every(
      (value) => value === true
    );
    setAllAgreed(allChecked);
  }, [agreementStates]);

  const toggleAllAgreements = () => {
    const newAllAgreed = !allAgreed;
    setAllAgreed(newAllAgreed);
    setAgreementStates({
      refundPolicy: newAllAgreed,
      privacyPolicy: newAllAgreed,
      thirdPartyConsent: newAllAgreed,
    });
  };

  const toggleAgreement = (key: keyof typeof agreementStates) => {
    setAgreementStates({
      ...agreementStates,
      [key]: !agreementStates[key],
    });
  };

  const divRef = useRef<HTMLDivElement>(null);
  const [headerFixed, setHeaderFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setHeaderFixed(true);
      } else {
        setHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(`/ext/invoice?key=${key}`);

        setGroup(res.group);
        setReservation(res.reservation);
        setPaymentInfomation(res.paymentInfomation);
        setCardCompanies(res.cardCompanies);
        setInstallments(res.installments);
        setRefundPolicy(res.refundPolicy);

        if (res.invoices.length > 0) {
          setInvoice(res.invoices[0]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
    trackEvent(amplitudeEvents.payDetailLand, { reservationNumber: key });
  }, [key]);

  useEffect(() => {
    if (invoice?.expiredDate) {
      const isPaid = invoice.expiredDate * 1000 - new Date().getTime() <= 0;
      setPaid(isPaid);
    }
  }, [invoice]);

  const handleInvoiceClick = async (cardCode: string, installment: string) => {
    try {
      const result = await api.get(
        `/ext/payment/url?key=${key}&pgType=secta9ine&cardCode=${cardCode}&installment=${installment}`
      );

      if (result) {
        window.open(result, "_blank");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // app이 있을경우 이동
  // useEffect(() => {
  //   window.open(`https://pawdly.onelink.me/EwU0/h3jgdx36`);
  // }, []);

  useEffect(() => {
    if (
      invoice?.status === "PAID" ||
      invoice?.status === "EXPIRED" ||
      invoice?.status === "B_CANCELED" ||
      invoice?.status === "U_CANCELED" ||
      invoice?.status === "CANCELED_REQUEST"
    ) {
      navigate(`/invoicedetail?key=${key}`);
    }
  }, [invoice]);

  if (_.isEmpty(invoice)) {
    return <></>;
  }

  return (
    <VFlex f-1 as-st c-c>
      <VFlex
        style={{
          maxWidth: 480,
          width: "100%",
          paddingBottom: 130,
          marginTop: 76,
        }}
      >
        <VFlex>
          <VFlex
            ref={divRef}
            bc-ww
            sdw-1={headerFixed}
            style={{
              width: "100%",
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              margin: "0 auto",
            }}
          >
            <HFlex a-c p-24 g-16>
              <SvgIcon
                size={24}
                icon={SvgIconType.Back}
                onClick={() => {
                  navigate(`/reservation?key=${key}`);
                }}
              />
              <VFlex t-22-600-s8>결제하기</VFlex>
            </HFlex>
          </VFlex>

          <VFlex p-16-b p-24-rl g-16>
            <VFlex g-4 t-14-s4>
              <HFlex g-12>
                <VFlex>요청 일시</VFlex>
                <VFlex>
                  {invoice &&
                    format(
                      new Date(invoice.created * 1000),
                      "yyyy.MM.dd. HH:mm:ss"
                    )}
                </VFlex>
              </HFlex>
              <HFlex g-12>
                <VFlex>예약 번호</VFlex>
                <VFlex>{reservation?.reservationNumber}</VFlex>
              </HFlex>
            </VFlex>

            {!paid && (
              <VFlex>
                <HFlex
                  ovf-h
                  p-16
                  g-12
                  a-s
                  bdr-12
                  style={{
                    border: "1px solid rgba(255, 195, 94, 0.60)",
                    background: "rgba(255, 195, 94, 0.10)",
                  }}
                >
                  <SvgIcon size={24} icon={SvgIconType.Info} />
                  <VFlex g-4>
                    <VFlex t-14-600-s8 l-n>
                      {invoice.type === "RESV_DEPOSIT"
                        ? "기한 내 결제시 예약이 확정됩니다."
                        : "기한 내 결제를 완료해주세요."}
                    </VFlex>
                    {invoice && (
                      <HFlex g-4 a-c>
                        <VFlex t-14-400-s7>
                          {format(
                            new Date(invoice.expiredDate * 1000),
                            "yy.MM.dd(EEE) a h:mm",
                            {
                              locale: ko,
                            }
                          )}
                        </VFlex>
                        <Timer
                          style={{
                            color: "#CD9732",
                          }}
                          time={
                            invoice.expiredDate * 1000 - new Date().getTime()
                          }
                        />
                      </HFlex>
                    )}
                  </VFlex>
                </HFlex>
              </VFlex>
            )}
          </VFlex>

          <Divider height={12} />

          <VFlex g-24 p-24>
            <HFlex
              a-c
              j-b
              g-16
              onClick={() => {
                setInfoVisible((prev) => !prev);
              }}
            >
              <VFlex t-22-600-s8>예약 정보</VFlex>
              <HFlex
                a-c
                g-4
                style={{
                  maxWidth: 196,
                }}
              >
                <SvgIcon
                  size={24}
                  icon={infoVisible ? SvgIconType.ArrUp : SvgIconType.ArrDown}
                />
              </HFlex>
            </HFlex>
            {infoVisible && (
              <VFlex bd-t4 bdr-16>
                <HFlex p-16 g-16 j-b a-c bd-b-t4>
                  <HFlex g-12 a-c>
                    {group?.attachment ? (
                      <Image
                        bdr-12
                        ovf-h
                        size={36}
                        src={group.attachment.path}
                      />
                    ) : (
                      <SvgIcon size={36} icon={SvgIconType.AvatarDefault} />
                    )}
                    <VFlex t-18-600-s8>{group?.title}</VFlex>
                  </HFlex>
                  <VFlex
                    p-8-tb
                    p-16-rl
                    bd-t5
                    bdr-12
                    t-14-600-s8
                    clickable
                    onClick={() => {
                      window.location.href = `tel://${group?.contact}`;
                    }}
                  >
                    문의하기
                  </VFlex>
                </HFlex>

                <VFlex p-16 g-4 bd-b-t4>
                  <HFlex g-8>
                    <VFlex t-16-600-s8>
                      {`${reservation?.pet.name}(${reservation?.pet.species.name})`}
                    </VFlex>
                    <VFlex p-8-rl c-c bd-t4 bdr-8 t-14-s6>
                      {reservation && statusString[reservation.status]}
                    </VFlex>
                  </HFlex>
                  <VFlex t-16-s4>{reservation?.reservationPeriod}</VFlex>
                </VFlex>

                <VFlex p-16 g-16>
                  <VFlex g-4 p-16-b bd-b-s8>
                    {reservation?.products.map((p) => {
                      return (
                        <HFlex key={p.id} g-16 j-b>
                          <VFlex t-16-s4>
                            {p.name}
                            {p.quantity > 1 && `*${p.quantity}`}
                          </VFlex>
                          <VFlex t-16-s8>
                            {numberWithCommas(
                              p.quantity > 1 ? p.price * p.quantity : p.price
                            )}
                            원
                          </VFlex>
                        </HFlex>
                      );
                    })}
                    {invoice?.extraAmount ? (
                      <HFlex g-16 j-b>
                        <VFlex t-16-s4>가격 추가</VFlex>
                        <VFlex t-16-600-bl1>
                          +{numberWithCommas(invoice.extraAmount)}원
                        </VFlex>
                      </HFlex>
                    ) : null}
                    {invoice?.discountAmount ? (
                      <HFlex g-16 j-b>
                        <VFlex t-16-s4>가격 할인</VFlex>
                        <VFlex t-16-600-bl1>
                          -{numberWithCommas(invoice.discountAmount)}원
                        </VFlex>
                      </HFlex>
                    ) : null}
                  </VFlex>
                  <VFlex g-4>
                    <HFlex g-16 j-b a-c>
                      <VFlex t-16-600-s8>상품 금액</VFlex>
                      <VFlex t-18-600-s8>
                        {numberWithCommas(invoice?.finalAmount ?? 0)}원
                      </VFlex>
                    </HFlex>
                    <VFlex a-e t-14-s6>
                      (상담을 통해 금액이 변경될 수 있습니다.)
                    </VFlex>
                  </VFlex>
                  {invoice?.type === "RESV_DEPOSIT" && (
                    <VFlex g-4 p-16-t bd-t-t4>
                      <HFlex g-16 j-b a-c>
                        <VFlex t-16-600-s8>예약금</VFlex>
                        <VFlex t-18-600-gr5>
                          {numberWithCommas(
                            paymentInfomation?.depositAmount ?? 0
                          )}
                          원
                        </VFlex>
                      </HFlex>
                      <VFlex a-e t-14-s6>
                        (상품 금액의 {invoice.rate}%)
                      </VFlex>
                    </VFlex>
                  )}
                </VFlex>
              </VFlex>
            )}
          </VFlex>

          <Divider height={12} />

          {/* {invoice?.type !== "RESV_DEPOSIT" && (
            <>
              <VFlex>
                <VFlex p-24 t-22-600-s8 bd-b-t2>
                  적립금/선불권
                </VFlex>
                <VFlex p-24 g-16>
                  <VFlex t-14-s4 l-n>
                    개인정보보호방침으로 인해 모바일 웹에서 해당 내용을 확인할
                    수 없습니다. 앱으로 이용 부탁드립니다.
                  </VFlex>
                  <VFlex
                    a-c
                    p-12
                    bd-t5
                    bdr-12
                    t-16-600-s8
                    clickable
                    onClick={() => {
                      trackEvent(amplitudeEvents.appDownloadBtnClick, {
                        location: "invoice",
                      });
                      openAppLink("invoice_to_check_app");
                    }}
                  >
                    앱에서 확인하기
                  </VFlex>
                </VFlex>
              </VFlex>

              <Divider height={12} />
            </>
          )} */}

          <VFlex>
            <VFlex p-24 t-22-600-s8 bd-b-t2>
              결제 정보
            </VFlex>
            <VFlex p-24 g-16>
              {invoice?.type === "RESV_DEPOSIT" ? (
                <HFlex g-16 bd-b-t4 p-16-b j-b>
                  <VFlex t-16-s4>예약금</VFlex>
                  <VFlex t-16-s8>
                    {numberWithCommas(invoice?.actualAmount ?? 0)}원
                  </VFlex>
                </HFlex>
              ) : (
                <VFlex g-8>
                  <HFlex g-16 j-b>
                    <VFlex t-16-s4>상품 금액</VFlex>
                    <VFlex t-16-s8>
                      {numberWithCommas(invoice?.finalAmount ?? 0)}원
                    </VFlex>
                  </HFlex>
                  {/* <HFlex g-16 bd-b-t4 p-16-b j-b>
                    <VFlex t-16-s4>적립금 사용</VFlex>
                    <VFlex t-16-600-bl1>
                      -
                      {numberWithCommas(
                        paymentInfomation?.usedGroupMileage ?? 0
                      )}
                      원
                    </VFlex>
                  </HFlex> */}
                </VFlex>
              )}
              <HFlex g-16 j-b>
                <VFlex t-18-600-s8>최종 결제 금액</VFlex>
                <VFlex t-20-600-gr5>
                  {numberWithCommas(paymentInfomation?.actualAmount ?? 0)}원
                </VFlex>
              </HFlex>
              {/* {invoice?.type !== "RESV_DEPOSIT" && group?.mileagePolicy && (
                <HFlex g-16 j-b>
                  <VFlex t-16-s4>
                    적립금액(
                    {paymentInfomation?.cardRewardPercentage}
                    %)
                  </VFlex>
                  <VFlex t-16-s8>
                    {paymentInfomation &&
                      numberWithCommas(
                        paymentInfomation?.expectedGroupMileage ?? 0
                      )}
                    원
                  </VFlex>
                </HFlex>
              )} */}
            </VFlex>
          </VFlex>

          <Divider height={12} />

          <VFlex>
            <HFlex
              a-c
              p-24
              j-b
              bd-b-t2
              onClick={() => {
                setServiceVisible((prev) => !prev);
              }}
            >
              <VFlex t-18-600-s8>결제 수단</VFlex>
              <HFlex
                a-c
                g-4
                style={{
                  maxWidth: 196,
                }}
              >
                {cardName ? (
                  <VFlex t-16-600-s8>{cardName}</VFlex>
                ) : (
                  <VFlex t-16-600-s2>결제 수단을 선택해주세요</VFlex>
                )}

                <SvgIcon
                  size={24}
                  icon={
                    serviceVisible ? SvgIconType.ArrUp : SvgIconType.ArrDown
                  }
                />
              </HFlex>
            </HFlex>
            {serviceVisible && (
              <VFlex p-24 g-16>
                <VFlex bd-t5 bdr-16>
                  <VFlex
                    a-c
                    p-16-tb
                    bd-b-t5
                    bc-s9={!cardVisible}
                    bc-ww={cardVisible}
                    t-20-ww={!cardVisible}
                    t-20-s9={cardVisible}
                    clickable
                    onClick={() => {
                      setCardVisible(false);
                      setCardName("");
                      setCardCode("");
                      setInstallment("");

                      trackEvent(amplitudeEvents.pawdlyPayClick, {
                        reservationNumber: key,
                      });
                    }}
                  >
                    <SvgIcon
                      icon={
                        cardVisible
                          ? SvgIconType.PawdlyPayB
                          : SvgIconType.PawdlyPayW
                      }
                    />
                  </VFlex>
                  <VFlex
                    style={{
                      padding: "24px 58px 32px",
                    }}
                  >
                    <VFlex
                      a-c
                      bc-t1
                      p-24-tb
                      p-16-rl
                      bdr-8
                      g-8
                      style={{
                        border: "1px dashed #AAB2AE",
                      }}
                      clickable
                      onClick={() => {
                        trackEvent(amplitudeEvents.addCardClick);
                        openAppLink("invoice_to_check_app");
                      }}
                    >
                      <SvgIcon size={24} icon={SvgIconType.AddCircle} />
                      <VFlex t-16-600-s8>카드 추가</VFlex>
                      <VFlex t-14-s4 l-n c-c>
                        카드를 등록하고 더 간편하게 결제하세요! 포들리 페이는
                        앱에서 사용할 수 있습니다.
                      </VFlex>
                    </VFlex>
                  </VFlex>
                </VFlex>
                <VFlex
                  a-c
                  j-b
                  p-16
                  bd-t4
                  bdr-16
                  bc-ww={!cardVisible}
                  bc-s9={cardVisible}
                  t-16-600-s8={!cardVisible}
                  t-16-600-ww={cardVisible}
                  clickable
                  onClick={() => {
                    setCardVisible(true);

                    trackEvent(amplitudeEvents.cardPayClick, {
                      reservationNumber: key,
                    });
                  }}
                >
                  신용/체크 카드
                </VFlex>
                {cardVisible && (
                  <VFlex g-10>
                    <HFlex
                      a-c
                      j-b
                      p-16
                      bd-t4
                      bdr-16
                      onClick={() => {
                        setCardBottomSheetOpened(true);
                      }}
                    >
                      <VFlex>{cardName ? cardName : "카드 선택"}</VFlex>
                      <SvgIcon size={24} icon={SvgIconType.ArrowDown} />
                    </HFlex>
                    <HFlex
                      a-c
                      j-b
                      p-16
                      bd-t4
                      bdr-16
                      bc-t1={
                        !cardName ||
                        (paymentInfomation &&
                          paymentInfomation.actualAmount < 50000)
                      }
                      t-16-g4={
                        !cardName ||
                        (paymentInfomation &&
                          paymentInfomation.actualAmount < 50000)
                      }
                      onClick={() => {
                        if (
                          !cardName ||
                          (paymentInfomation &&
                            paymentInfomation.actualAmount < 50000)
                        ) {
                          return;
                        } else {
                          setInstallmentBottomSheetOpened(true);
                        }
                      }}
                    >
                      <VFlex>{installment ? installment : "일시불"}</VFlex>
                      <SvgIcon size={24} icon={SvgIconType.ArrowDown} />
                    </HFlex>
                  </VFlex>
                )}
              </VFlex>
            )}
          </VFlex>

          <Divider height={12} />

          <VFlex>
            <VFlex p-24 t-22-600-s8 bd-b-t2>
              {invoice?.type === "RESV_DEPOSIT" ? "예약금" : "결제"} 안내사항
            </VFlex>
            <VFlex p-24 t-16-s6 l-n>
              {invoice?.noticeMessage}
            </VFlex>
          </VFlex>

          <Divider height={12} />

          <VFlex>
            <VFlex p-24 t-22-600-s8 bd-b-t2>
              환불 정책 및 필수 약관 동의
            </VFlex>
            <VFlex p-24 g-16>
              <HFlex a-c g-8 clickable onClick={toggleAllAgreements}>
                <SvgIcon
                  size={24}
                  icon={
                    allAgreed
                      ? SvgIconType.CheckboxCircle
                      : SvgIconType.UncheckboxCircle
                  }
                />
                <VFlex t-18-600-s8>모두 동의합니다.</VFlex>
              </HFlex>
              <HFlex
                a-c
                g-8
                clickable
                onClick={() => toggleAgreement("refundPolicy")}
              >
                <SvgIcon
                  size={24}
                  icon={
                    agreementStates.refundPolicy
                      ? SvgIconType.CheckboxCircle
                      : SvgIconType.UncheckboxCircle
                  }
                />
                <VFlex t-16-s6>취소 및 환불 규정 동의</VFlex>
              </HFlex>
              <VFlex bd-t-t5 bd-b-t5>
                {refundPolicy?.map((refund, i) => {
                  const parts = refund.refundRate.split(
                    new RegExp(`(${refund.rate}%)`)
                  );
                  return (
                    <HFlex
                      a-c
                      bd-b-t5={i !== refundPolicy.length - 1}
                      key={refund.period}
                    >
                      <VFlex f-1 p-12-tb p-16-rl bc-t2 bd-r-t5 t-14-600-s7>
                        {refund.cancelDate}
                      </VFlex>
                      <HFlex f-1 p-12-tb p-16-rl g-4>
                        {parts.map((part, index) => (
                          <VFlex
                            t-14-600-rd1={part === `${refund.rate}%`}
                            t-14-400-s4={part !== `${refund.rate}%`}
                            key={index}
                          >
                            {part}
                          </VFlex>
                        ))}
                      </HFlex>
                    </HFlex>
                  );
                })}
              </VFlex>
              <HFlex a-c j-b g-16>
                <HFlex
                  g-8
                  a-c
                  clickable
                  onClick={() => toggleAgreement("privacyPolicy")}
                >
                  <SvgIcon
                    size={24}
                    icon={
                      agreementStates.privacyPolicy
                        ? SvgIconType.CheckboxCircle
                        : SvgIconType.UncheckboxCircle
                    }
                  />
                  <VFlex t-16-s6>개인정보 처리방침 동의</VFlex>
                </HFlex>
                <VFlex
                  t-16-s4
                  style={{ textDecorationLine: "underline" }}
                  clickable
                  onClick={() => {
                    window.open(
                      "https://pawdly.notion.site/75b65ca9b6a94a488f41fb2dd02ba1a3",
                      "_blank"
                    );
                  }}
                >
                  보기
                </VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <HFlex
                  g-8
                  a-c
                  clickable
                  onClick={() => toggleAgreement("thirdPartyConsent")}
                >
                  <SvgIcon
                    size={24}
                    icon={
                      agreementStates.thirdPartyConsent
                        ? SvgIconType.CheckboxCircle
                        : SvgIconType.UncheckboxCircle
                    }
                  />
                  <VFlex t-16-s6>개인정보 제3자 제공 동의</VFlex>
                </HFlex>
                <VFlex
                  t-16-s4
                  style={{ textDecorationLine: "underline" }}
                  clickable
                  onClick={() => {
                    window.open(
                      "https://pawdly.notion.site/75b65ca9b6a94a488f41fb2dd02ba1a3",
                      "_blank"
                    );
                  }}
                >
                  보기
                </VFlex>
              </HFlex>
            </VFlex>
          </VFlex>

          <VFlex
            p-24-trl
            bc-t1
            t-14-s4
            l-n
            style={{
              paddingBottom: 74,
            }}
          >
            (주)포들러스는 통신판매중개자이며 통신판매의 당사자가 아닙니다.
            (주)포들러스는 예약 및 구매관련 통신판매업자가 제공하는 상품, 거래
            정보 및 거래 등에 대하여 책임을 지지 않습니다.
          </VFlex>
        </VFlex>

        {cardCompanies && (
          <InvoiceCardBottomSheet
            open={cardBottomSheetOpened}
            onDismiss={() => setCardBottomSheetOpened(false)}
            onComplete={(card, code) => {
              setCardBottomSheetOpened(false);
              setCardName(card);
              setCardCode(code);
            }}
            cardCompanies={cardCompanies}
            cardName={cardName}
          />
        )}

        {cardName && (
          <InvoiceInstallmentBottomSheet
            open={installmentBottomSheetOpened}
            onDismiss={() => setInstallmentBottomSheetOpened(false)}
            onComplete={(value) => {
              setInstallmentBottomSheetOpened(false);
              setInstallment(value);
            }}
            installments={installments}
            installment={installment}
            setInstallment={setInstallment}
          />
        )}
      </VFlex>

      <VFlex
        p-16-tb
        p-24-rl
        bc-ww
        g-16
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
          boxShadow:
            "0px 1px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 24px 0px rgba(0, 0, 0, 0.12)",
        }}
      >
        <HFlex j-b a-c g-16>
          <VFlex t-16-600-s8>최종 결제 금액</VFlex>
          <VFlex t-20-600-gr5>
            {numberWithCommas(paymentInfomation?.actualAmount ?? 0)}원
          </VFlex>
        </HFlex>
        <VFlex
          p-16-tb
          p-24-rl
          c-c
          bdr-16
          t-18-600-ww
          bc-pt
          style={{
            opacity: allAgreed && cardName ? 1 : 0.5,
          }}
          onClick={async () => {
            if (!allAgreed || !cardName) {
              return;
            }

            handleInvoiceClick(
              cardCode,
              installment.includes("개월") ? installment.split("개월")[0] : "0"
            );

            navigate(`/invoicecomplete?key=${key}`);
          }}
        >
          {numberWithCommas(paymentInfomation?.actualAmount ?? 0)}원 결제하기
        </VFlex>
      </VFlex>
    </VFlex>
  );
}
