export const days = ["일", "월", "화", "수", "목", "금", "토"];

export const daysEnglish = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const statusString = [
  "예약 문의",
  "문의종료",
  "예약 진행중",
  "예약 확정",
  "이용 완료",
  "예약 취소",
  "예약 취소",
  "미방문",
  "삭제",
  "삭제",
  "방문 확정",
  "임시저장",
  "가예약",
  "예약 취소",
];

export const invoiceStatusString = [
  { status: "UNPAID", text: "결제 대기중" },
  { status: "PAID", text: "결제 완료" },
  { status: "U_CANCELED", text: "결제 취소" }, // 고객의 예약 취소
  { status: "B_CANCELED", text: "결제 취소" }, // 파트너의 예약 취소
  { status: "EXPIRED", text: "결제 요청 취소" }, // 기한만료로 인한 취소
  { status: "CANCELED_REQUEST", text: "결제 요청 취소" }, // 파트너의 결제 취소 요청
];
