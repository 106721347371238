import { useCallback, useState } from "react";

import { daysEnglish } from "../common/constants";
import { format } from "date-fns";
import { BottomSheet } from "react-spring-bottom-sheet";
import { timeSlotToString } from "./Schedule";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import Divider from "../layouts/Divider";

import "react-spring-bottom-sheet/dist/style.css";

export type TimeSlots = {
  globalTimeSlots: "anytime" | "asap" | undefined;
  morningTimes: number[];
  afternoonTimes: number[];
  isMorningAnytime: boolean;
  isAfternoonAnytime: boolean;
};

function getMinutesFromTimeString(timeString: string) {
  const [hour, minute] = timeString.split(":");
  return parseInt(hour) * 60 + parseInt(minute);
}

export function DateNDateNDateNTimeBottomSheet({
  partner,
  date,
  open,
  onDismiss,
  onComplete,
}: {
  partner: any;
  date: Date;
  open: boolean;
  onDismiss: () => void;
  onComplete: (value: TimeSlots) => void;
}) {
  const defaultHours = {
    dayOfWeek: "",
    start: "11:00",
    end: "20:00",
  };

  const businessHours = partner.businessHours
    ? partner.businessHours.find(
        (hours: any) => hours.dayOfWeek === daysEnglish[date?.getDay()]
      )
    : defaultHours;

  const startMinutes = businessHours
    ? getMinutesFromTimeString(businessHours.start)
    : "00:00";
  const endMinutes = businessHours
    ? getMinutesFromTimeString(businessHours.end) - partner.interval
    : "00:00";
  const [globalTimeSlots, setGlobalTimeSlots] = useState<"anytime" | "asap">();
  const [isMorningAnytime, setIsMorningAnytime] = useState<boolean>(false);
  const [morningTimes, setMorningTimes] = useState<number[]>([]);
  const [isAfternoonAnytime, setIsAfternoonAnytime] = useState<boolean>(false);
  const [afternoonTimes, setAfternoonTimes] = useState<number[]>([]);
  const isCompleted = useCallback(() => {
    return (
      !!globalTimeSlots ||
      isMorningAnytime ||
      isAfternoonAnytime ||
      morningTimes.length > 0 ||
      afternoonTimes.length > 0
    );
  }, [
    globalTimeSlots,
    isMorningAnytime,
    isAfternoonAnytime,
    morningTimes,
    afternoonTimes,
  ]);

  const isCheckTimeLength = useCallback(() => {
    return (
      morningTimes.length + afternoonTimes.length >= 3 ||
      (isMorningAnytime && afternoonTimes.length >= 2) ||
      (isAfternoonAnytime && morningTimes.length >= 2)
    );
  }, [morningTimes, afternoonTimes, isMorningAnytime, isAfternoonAnytime]);

  if (!partner) {
    return null;
  }

  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={60}>
        <Text f-1 t-22-600>
          희망 시작 시간 선택
        </Text>
      </HFlex>
      <Divider m-24-rl />
      <VFlex
        p-24-trl
        g-32
        height={700}
        style={{
          paddingBottom: 220,
          overflow: "scroll",
        }}
      >
        <VFlex t-14-g6 l-n>
          최대 세개의 시간까지 선택할 수 있으며 업체의 선택에 따라 예약이
          확정됩니다.
        </VFlex>
        <HFlex g-8>
          {partner.asap && (
            <HFlex
              c-c
              width={120}
              height={48}
              bc-t1
              bdr-16
              bd-t1={globalTimeSlots !== "asap"}
              bd-gr5={globalTimeSlots === "asap"}
              t-16-s6={globalTimeSlots !== "asap"}
              t-16-gr5={globalTimeSlots === "asap"}
              style={{
                boxSizing: "border-box",
              }}
              onClick={() => {
                setIsMorningAnytime(false);
                setMorningTimes([]);
                setIsAfternoonAnytime(false);
                setAfternoonTimes([]);
                if (globalTimeSlots === "asap") {
                  setGlobalTimeSlots(undefined);
                } else {
                  setGlobalTimeSlots("asap");
                }
              }}
            >
              가능한 빨리
            </HFlex>
          )}
          {partner.anyTime && (
            <HFlex
              c-c
              width={120}
              height={48}
              bc-t1
              bdr-16
              bd-t1={globalTimeSlots !== "anytime"}
              bd-gr5={globalTimeSlots === "anytime"}
              t-16-s6={globalTimeSlots !== "anytime"}
              t-16-gr5={globalTimeSlots === "anytime"}
              style={{
                boxSizing: "border-box",
              }}
              onClick={() => {
                setIsMorningAnytime(false);
                setMorningTimes([]);
                setIsAfternoonAnytime(false);
                setAfternoonTimes([]);
                if (globalTimeSlots === "anytime") {
                  setGlobalTimeSlots(undefined);
                } else {
                  setGlobalTimeSlots("anytime");
                }
              }}
            >
              아무시간
            </HFlex>
          )}
        </HFlex>
        <VFlex g-16>
          <Text t-18-s6>오전</Text>
          <HFlex g-8 style={{ flexWrap: "wrap" }}>
            {true && (
              <HFlex
                c-c
                width={120}
                height={48}
                bc-t1
                bdr-16
                bd-t1={!isMorningAnytime}
                bd-gr5={isMorningAnytime}
                t-16-s6={!isMorningAnytime}
                t-16-gr5={isMorningAnytime}
                style={{
                  boxSizing: "border-box",
                }}
                onClick={() => {
                  setGlobalTimeSlots(undefined);
                  setMorningTimes([]);
                  setIsMorningAnytime(!isMorningAnytime);
                }}
              >
                아무시간
              </HFlex>
            )}
            {[...new Array((12 * 60) / partner.interval)]
              .map((_, i) => i * partner.interval)
              .filter((i) => i >= startMinutes && i <= endMinutes)
              .map((i) => (
                <HFlex
                  key={i}
                  c-c
                  width={120}
                  height={48}
                  bc-t1
                  bdr-16
                  bd-t1={isMorningAnytime || !morningTimes.includes(i)}
                  bd-gr5={!isMorningAnytime && morningTimes.includes(i)}
                  t-16-s6={isMorningAnytime || !morningTimes.includes(i)}
                  t-16-gr5={!isMorningAnytime && morningTimes.includes(i)}
                  style={{
                    boxSizing: "border-box",
                  }}
                  onClick={() => {
                    setGlobalTimeSlots(undefined);
                    setIsMorningAnytime(false);
                    if (morningTimes.includes(i)) {
                      setMorningTimes(
                        morningTimes.filter((time) => time !== i)
                      );
                    } else {
                      if (isCheckTimeLength()) {
                        return alert(
                          "최대 세 개의 시간까지 선택할 수 있습니다."
                        );
                      }
                      setMorningTimes([...morningTimes, i].sort());
                    }
                  }}
                >
                  {format(new Date(2000, 1, 1, 0, i), "hh:mm")}
                </HFlex>
              ))}
          </HFlex>
        </VFlex>
        <VFlex g-16>
          <Text t-18-s6>오후</Text>
          <HFlex g-8 style={{ flexWrap: "wrap" }}>
            {true && (
              <HFlex
                c-c
                width={120}
                height={48}
                bc-t1
                bdr-16
                bd-t1={!isAfternoonAnytime}
                bd-gr5={isAfternoonAnytime}
                t-16-s6={!isAfternoonAnytime}
                t-16-gr5={isAfternoonAnytime}
                style={{
                  boxSizing: "border-box",
                }}
                onClick={() => {
                  setGlobalTimeSlots(undefined);
                  setAfternoonTimes([]);
                  setIsAfternoonAnytime(!isAfternoonAnytime);
                }}
              >
                아무시간
              </HFlex>
            )}
            {[...new Array((12 * 60) / partner.interval)]
              .map((_, i) => i * partner.interval + 12 * 60)
              .filter((i) => i >= startMinutes && i <= endMinutes)
              .map((i) => (
                <HFlex
                  key={i}
                  c-c
                  width={120}
                  height={48}
                  bc-t1
                  bdr-16
                  bd-t1={isAfternoonAnytime || !afternoonTimes.includes(i)}
                  bd-gr5={!isAfternoonAnytime && afternoonTimes.includes(i)}
                  t-16-s6={isAfternoonAnytime || !afternoonTimes.includes(i)}
                  t-16-gr5={!isAfternoonAnytime && afternoonTimes.includes(i)}
                  style={{
                    boxSizing: "border-box",
                  }}
                  onClick={() => {
                    setGlobalTimeSlots(undefined);
                    setIsAfternoonAnytime(false);
                    if (afternoonTimes.includes(i)) {
                      setAfternoonTimes(
                        afternoonTimes.filter((time) => time !== i)
                      );
                    } else {
                      if (isCheckTimeLength()) {
                        return alert(
                          "최대 세 개의 시간까지 선택할 수 있습니다."
                        );
                      }
                      setAfternoonTimes([...afternoonTimes, i].sort());
                    }
                  }}
                >
                  {format(new Date(2000, 1, 1, 0, i), "h:mm")}
                </HFlex>
              ))}
          </HFlex>
        </VFlex>
      </VFlex>

      <VFlex
        p-16-t
        p-24-rl
        p-40-b
        bc-ww
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
          borderTop: "1px solid #EBEDEC",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <VFlex a-c t-16-s8 height={24} ovf-s>
          {timeSlotToString({
            globalTimeSlots,
            morningTimes,
            afternoonTimes,
            isMorningAnytime,
            isAfternoonAnytime,
          })}
        </VFlex>
        <VFlex
          f-1
          c-c
          bdr-16
          t-18-ww
          bc-pt
          m-12-t
          p-20-tb
          style={{
            opacity: isCompleted() ? 1 : 0.5,
          }}
          onClick={() => {
            if (!isCompleted()) {
              return alert("희망 시작 시간을 선택해주세요.");
            }

            onComplete({
              globalTimeSlots,
              morningTimes,
              afternoonTimes,
              isMorningAnytime,
              isAfternoonAnytime,
            });
          }}
        >
          저장
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default DateNDateNDateNTimeBottomSheet;
