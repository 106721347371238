import { HTMLAttributes, useEffect, useState } from "react";
import VFlex from "../layouts/VFlex";
import { FontProps } from "../layouts/index_generated";

type TimerProps = {
  time: number;
  color?: string;
} & HTMLAttributes<HTMLDivElement> &
  FontProps;

export default function Timer({ time, style, ...props }: TimerProps) {
  const TIMES = time;
  const INTERVAL = 1000;
  const [timeLeft, setTimeLeft] = useState<number>(TIMES);

  const days = String(Math.floor(timeLeft / (1000 * 60 * 60 * 24))).padStart(
    2,
    "0"
  );
  const houres = String(
    Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  ).padStart(2, "0");
  const minutes = String(Math.floor((timeLeft / (1000 * 60)) % 60)).padStart(
    2,
    "0"
  );
  const second = String(Math.floor((timeLeft / 1000) % 60)).padStart(2, "0");

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - INTERVAL);
    }, INTERVAL);

    if (timeLeft <= 0) {
      clearInterval(timer);
      setTimeLeft(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeLeft]);

  return (
    <VFlex
      t-14-600
      style={{
        ...style,
      }}
    >
      {Number(days) > 0 && `${days}일 `}
      {Number(houres) > 0 && `${houres}시간 `}
      {`${minutes}분 ${second}초 남음`}
    </VFlex>
  );
}
