import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import _ from "lodash";
import { format } from "date-fns";
import { toast } from "react-hot-toast";
import { ko } from "date-fns/locale";

import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import Image from "../layouts/Image";
import FloatingBanner, { FloatingKind } from "../components/FloatingBanner";

import api from "../common/api";

import CalendarBottomSheet from "./CalendarBottomSheet";
import TimeBottomSheet, { TimeSlots } from "./TimeBottomSheet";
import ServiceBottomSheet, { Service } from "./ServiceBottomSheet";
import NoteBottomSheet from "./NoteBottomSheet";
import ReservationDoneBottomSheet from "./ReservationDoneBottomSheet";

import SvgIcon, { SvgIconType } from "../svg";

import { useAppLinkContext } from "../modules/useAppLink";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../common/hooks/useAmplitudeTrack";

export function timeSlotToString(timeSlot: TimeSlots) {
  if (timeSlot.globalTimeSlots === "anytime") {
    return "아무시간";
  } else if (timeSlot.globalTimeSlots === "asap") {
    return "가능한 빨리";
  }

  const times = [];
  if (timeSlot.isMorningAnytime) {
    times.push("오전 아무시간");
  } else if (timeSlot.morningTimes.length > 0) {
    times.push(
      `${timeSlot.morningTimes
        .map((time) =>
          format(new Date(2000, 1, 1, 0, time), "a h:mm", { locale: ko })
        )
        .join(", ")}`
    );
  }

  if (timeSlot.isAfternoonAnytime) {
    times.push("오후 아무시간");
  } else if (timeSlot.afternoonTimes.length > 0) {
    times.push(
      `${timeSlot.afternoonTimes
        .map((time) =>
          format(new Date(2000, 1, 1, 0, time), "a h:mm", { locale: ko })
        )
        .join(", ")}`
    );
  }

  return times.join(", ");
}

function timeSlotToStringEvent(timeSlot: TimeSlots) {
  if (timeSlot.globalTimeSlots === "anytime") {
    return "아무시간";
  } else if (timeSlot.globalTimeSlots === "asap") {
    return "가능한 빨리";
  }

  const times = [];
  if (timeSlot.isMorningAnytime) {
    times.push("오전 아무시간");
  } else if (timeSlot.morningTimes.length > 0) {
    times.push(`${timeSlot.morningTimes.map((time) => time).join(", ")}`);
  }

  if (timeSlot.isAfternoonAnytime) {
    times.push("오후 아무시간");
  } else if (timeSlot.afternoonTimes.length > 0) {
    times.push(`${timeSlot.afternoonTimes.map((time) => time).join(", ")}`);
  }

  return times.join(", ");
}

export default function Schedule() {
  const { initProperties } = useAppLinkContext();
  const { setIdentifyObj, trackEvent } = useAmplitudeTrack();

  const { state } = useLocation();
  const navigate = useNavigate();
  const { group, pet, subscriber, type } = state;
  const [date, setDate] = useState<Date>();
  const [time, setTime] = useState<TimeSlots>();
  const [service, setService] = useState<Record<string, Service[]>>();
  const [note, setNote] = useState<string>();
  const [files, setFiles] = useState<File[]>();
  const [stage, setStage] = useState<number>(0);

  const [dateBottomSheetOpened, setDateBottomSheetOpened] =
    useState<boolean>(false);
  const [timeBottomSheetOpened, setTimeBottomSheetOpened] =
    useState<boolean>(false);
  const [serviceBottomSheetOpened, setServiceBottomSheetOpened] =
    useState<boolean>(false);
  const [noteBottomSheetOpened, setNoteBottomSheetOpened] =
    useState<boolean>(false);
  const [doneBottomSheetOpened, setDoneBottomSheetOpened] =
    useState<boolean>(false);
  const [bannerOpened, setBannerOpened] = useState(true);

  const isCompleted = useCallback(() => {
    return date && time && service;
  }, [date, time, service]);

  const [partner, setPartner] = useState<any>();
  useEffect(() => {
    if (group) {
      (async () => {
        const partner = await api.get(`/rest/group/${group.id}`);
        setPartner(partner);
      })();
    }
  }, [group]);
  useEffect(() => {
    initProperties({ petId: pet.id, groupId: group.id });
    setIdentifyObj("petId", pet.id);
    setIdentifyObj("groupId", group.id);
    trackEvent(amplitudeEvents.inquiryLand);
  }, [group, pet]);

  const submitReservation = async () => {
    const formData = new FormData();

    formData.append("type", type);
    formData.append("groupId", partner.id);
    formData.append("petId", pet.id);
    formData.append("userId", subscriber.id);
    formData.append(
      "reservationDate",
      Math.floor((date?.getTime() ?? 0) / 1000).toString()
    );

    const timeTypes = [
      ...(time?.globalTimeSlots === "asap"
        ? ["ASAP"]
        : time?.globalTimeSlots === "anytime"
        ? ["ANY_TIME"]
        : []),
      ...(time?.isMorningAnytime ? ["AM_ANY_TIME"] : []),
      ...(time?.isAfternoonAnytime ? ["PM_ANY_TIME"] : []),
      ...((time?.afternoonTimes?.length ?? 0) > 0 ||
      (time?.morningTimes?.length ?? 0) > 0
        ? ["FIXED_TIME"]
        : []),
    ];
    timeTypes.forEach((t) => formData.append("timeTypes", t));
    const times = [
      ...(time?.morningTimes ?? []),
      ...(time?.afternoonTimes ?? []),
    ].map((time) => format(new Date(2000, 1, 1, 0, time), "HH:mm"));
    times.forEach((t) => formData.append("time", t));

    _.flatMap(service).forEach((s, index) => {
      formData.append(`products[${index}].quantity`, "1");
      formData.append(`products[${index}].product.id`, s.id.toString());
    });

    note && formData.append("requestNote", note);
    files &&
      _.flatMap(files).forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

    const res = await api.post(
      `/ext/reservation/inquiry?platform=USER_WEB`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    navigate(`/reservation?key=${res}`, {
      state: true,
    });
  };

  return (
    <VFlex f-1 as-st c-c style={{ backgroundColor: "#F2F5F4" }}>
      <VFlex p-24 style={{ maxWidth: 480, minHeight: 900, width: "100%" }}>
        <VFlex c-c height={60}>
          <SvgIcon width={128} height={28} icon={SvgIconType.Logo} />
        </VFlex>
        <HFlex p-16-t p-32-b>
          <Text t-24-600-s8>예약 문의</Text>
        </HFlex>
        <VFlex g-8>
          <HFlex a-c p-16 bdr-24 bc-ww g-30>
            <SvgIcon icon={SvgIconType.Staff} />
            <VFlex f-1>
              <Text t-14-s4>반려동물명</Text>
              <Text t-18-600-s7>
                {Array.isArray(pet)
                  ? pet.map((v) => {
                      return v.name;
                    })
                  : pet?.name}
              </Text>
            </VFlex>
          </HFlex>

          <HFlex a-c p-16 bdr-24 bc-ww g-30>
            <SvgIcon icon={SvgIconType.Store} />
            <VFlex f-1>
              <Text t-14-s4>업체</Text>
              <Text t-18-600-s7>{partner?.title}</Text>
            </VFlex>
          </HFlex>

          <HFlex
            a-c
            p-16
            bdr-24
            bc-ww
            g-30
            style={{
              minHeight: 78,
            }}
            onClick={() => {
              setDateBottomSheetOpened(true);
            }}
          >
            <SvgIcon icon={SvgIconType.Date} />
            <VFlex f-1>
              {date && <Text t-14-s4>희망 날짜</Text>}
              {date ? (
                <Text t-18-600-s7 l-1>
                  {format(date, "M월 d일 EEEE", { locale: ko })}
                </Text>
              ) : (
                <Text t-18-s2 l-1>
                  희망 날짜 선택
                </Text>
              )}
            </VFlex>
          </HFlex>

          <HFlex
            a-c
            p-16
            bdr-24
            bc-ww
            g-30
            style={{
              minHeight: 78,
            }}
            onClick={() => {
              if (!date) {
                return toast.error("희망 날짜를 먼저 선택해주세요");
              }

              setTimeBottomSheetOpened(true);
            }}
          >
            <SvgIcon icon={SvgIconType.Time} />
            <VFlex f-1>
              {time && <Text t-14-s4>희망 시간</Text>}
              {time ? (
                <Text t-18-600-s7 l-1>
                  {timeSlotToString(time)}
                </Text>
              ) : (
                <Text t-18-s2 l-1>
                  희망 시간 선택
                </Text>
              )}
            </VFlex>
          </HFlex>

          <HFlex
            a-c
            p-16
            bdr-24
            bc-ww
            g-30
            style={{
              minHeight: 78,
            }}
            onClick={() => {
              if (!time) {
                return toast.error("희망 시간을 먼저 선택해주세요");
              }

              setServiceBottomSheetOpened(true);
            }}
          >
            <SvgIcon icon={SvgIconType.Service} />
            <VFlex f-1>
              {_.flatMap(service).length > 0 && <Text t-14-s4>서비스명</Text>}
              {_.flatMap(service).length > 0 ? (
                <Text t-18-600-s7 l-1>
                  {_.flatMap(service)
                    .map((v) => v.name)
                    .join(", ")}
                </Text>
              ) : (
                <Text t-18-s2 l-1>
                  서비스명 선택
                </Text>
              )}
            </VFlex>
          </HFlex>

          <HFlex
            p-16
            bdr-24
            bc-ww
            g-30
            style={{
              minHeight: 78,
            }}
            onClick={() => setNoteBottomSheetOpened(true)}
          >
            <SvgIcon size={48} icon={SvgIconType.Memo} />
            <VFlex f-1>
              {note && <Text t-14-s4>요청사항</Text>}
              {note ? (
                <Text t-18-600-s7 l-1>
                  {note}
                </Text>
              ) : (
                <Text
                  t-18-s2
                  l-1
                  style={{
                    lineHeight: "48px",
                  }}
                >
                  요청사항 입력
                </Text>
              )}
              {files && (
                <HFlex g-8>
                  {files.map((file, i) => {
                    return (
                      <VFlex
                        key={i}
                        p-12-t
                        rel
                        style={{
                          display: i < 4 ? "block" : "none",
                        }}
                      >
                        <Image
                          width={56}
                          height={56}
                          bdr-16
                          src={URL.createObjectURL(file)}
                        />
                        {files.length > 4 && i === 3 && (
                          <VFlex
                            abs
                            a-c
                            j-c
                            width={"100%"}
                            height={56}
                            t-20-600-ww
                            bdr-16
                            style={{
                              top: 12,
                              left: 0,
                              background: "rgba(0,0,0,0.3)",
                            }}
                          >
                            +{files.length - 4}
                          </VFlex>
                        )}
                      </VFlex>
                    );
                  })}
                </HFlex>
              )}
            </VFlex>
          </HFlex>
        </VFlex>
      </VFlex>

      {bannerOpened && (
        <VFlex
          a-st
          p-24
          height={128}
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000001,
          }}
        >
          <FloatingBanner
            kind={FloatingKind.Inquriy}
            closeBanner={() => setBannerOpened(false)}
          />
        </VFlex>
      )}
      <VFlex
        a-st
        p-24
        height={112}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
        }}
      >
        <VFlex
          f-1
          c-c
          bdr-16
          t-18-ww
          bc-pt
          style={{ opacity: isCompleted() ? 1 : 0.5 }}
          onClick={async () => {
            if (!isCompleted()) {
              return toast.error(
                "희망 날짜, 희망 시간, 서비스명을 선택해주세요"
              );
            }
            setDoneBottomSheetOpened(true);
          }}
        >
          예약 문의하기
        </VFlex>
      </VFlex>
      {partner && (
        <>
          <CalendarBottomSheet
            partner={partner}
            open={dateBottomSheetOpened}
            onDismiss={() => setDateBottomSheetOpened(false)}
            onComplete={(value) => {
              setDateBottomSheetOpened(false);
              setDate(value);

              if (stage < 3) {
                setStage(stage + 1);
                setTimeBottomSheetOpened(true);
              }
            }}
          />
          <TimeBottomSheet
            partner={partner}
            date={date!}
            open={timeBottomSheetOpened}
            onDismiss={() => setTimeBottomSheetOpened(false)}
            onComplete={(value) => {
              setTimeBottomSheetOpened(false);
              setTime(value);

              if (stage < 3) {
                setStage(stage + 1);
                setServiceBottomSheetOpened(true);
              }
            }}
          />
          <ServiceBottomSheet
            open={serviceBottomSheetOpened}
            onDismiss={() => setServiceBottomSheetOpened(false)}
            onComplete={(value) => {
              setServiceBottomSheetOpened(false);
              setService(value);

              if (stage < 3) {
                setStage(stage + 1);
                setNoteBottomSheetOpened(true);
              }
            }}
          />
          <NoteBottomSheet
            open={noteBottomSheetOpened}
            onDismiss={() => setNoteBottomSheetOpened(false)}
            onComplete={(value, files) => {
              setNoteBottomSheetOpened(false);
              setNote(value);
              setFiles(files);

              if (stage < 3) {
                setStage(stage + 1);
              }
            }}
          />
          <ReservationDoneBottomSheet
            open={doneBottomSheetOpened}
            onDismiss={() => setDoneBottomSheetOpened(false)}
            onComplete={async () => {
              setDoneBottomSheetOpened(false);
              trackEvent(amplitudeEvents.inquiryComplete);
              submitReservation();
            }}
          />
        </>
      )}
    </VFlex>
  );
}
