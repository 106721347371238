import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BottomSheet } from "react-spring-bottom-sheet";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import TextArea from "../layouts/TextArea";
import FileUpload from "../layouts/FileUpload";
import Image from "../layouts/Image";
import Divider from "../layouts/Divider";
import Absolute from "../layouts/Absolute";
import SvgIcon, { SvgIconType } from "../svg";

import "react-spring-bottom-sheet/dist/style.css";

function PhotoSection({ files, setPhotosView }: any) {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <VFlex
      p-24-t
      p-24-b
      bc-b
      style={{
        position: "fixed",
        top: 0,
        maxWidth: 480,
        width: "100%",
        height: "100vh",
        zIndex: 9999999,
      }}
    >
      <HFlex
        a-c
        j-c
        height={60}
        style={{
          position: "relative",
        }}
      >
        <HFlex>
          <Text t-18-600-ww>{slideIndex + 1}</Text>
          <Text t-18-600-s4>/{files.length}</Text>
        </HFlex>
        <Absolute
          left={"auto"}
          bottom={"auto"}
          top={"auto"}
          right={24}
          style={{ top: "50%", zIndex: 100000, transform: "translateY(-50%)" }}
        >
          <SvgIcon
            size={24}
            icon={SvgIconType.CloseW}
            onClick={() => setPhotosView(false)}
          />
        </Absolute>
      </HFlex>

      <Swiper
        style={{
          width: "100%",
          maxHeight: 720,
        }}
        slidesPerView={1}
        threshold={10}
        centeredSlides={true}
        onActiveIndexChange={(swiper) => setSlideIndex(swiper.activeIndex)}
      >
        {files.map((file: any, i: number) => (
          <SwiperSlide key={i}>
            <VFlex
              style={{
                minHeight: 600,
                background: `url("${URL.createObjectURL(
                  file
                )}") no-repeat center / contain`,
              }}
            ></VFlex>
          </SwiperSlide>
        ))}
      </Swiper>
    </VFlex>
  );
}

function NoteBottomSheet({
  open,
  onDismiss,
  onComplete,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: (value: string, files: any) => void;
}) {
  const [files, setFiles] = useState<File[]>();
  const [memo, setMemo] = useState("");
  const [photosView, setPhotosView] = useState(false);

  const removeFile = (idx: number) => {
    const newFiles =
      files &&
      files.filter((file, i) => {
        return idx !== i;
      });
    setFiles(newFiles);
  };

  return photosView ? (
    <PhotoSection files={files} setPhotosView={setPhotosView} />
  ) : (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={60}>
        <Text f-1 t-22-700>
          요청사항
        </Text>
      </HFlex>
      <Divider m-24-rl />
      <VFlex>
        <VFlex p-24-trl t-16-gd l-n>
          반려동물의 최근 상태를 보여주는 사진을 업로드 해주세요
        </VFlex>
        <VFlex p-24-t p-24-l p-16-b>
          <HFlex g-4>
            <Text t-18-600-s6 p-24-b>
              사진 등록하기
            </Text>
            {files && files?.length > 0 && (
              <Text t-18-600-pt>{files?.length}</Text>
            )}
          </HFlex>
          <HFlex>
            <label
              htmlFor="pet-image-upload"
              style={{
                width: 56,
                height: 56,
                backgroundColor: "#F1F6F4",
                borderRadius: 16,
              }}
            >
              <VFlex f-1 c-c width={56} height={56}>
                <Image src={`/image/camera.png`} />
              </VFlex>
              <FileUpload
                id="pet-image-upload"
                onLoad={async (f: File[]) => {
                  if (!files) {
                    setFiles(f);
                  } else {
                    setFiles([...files!, ...f]);
                  }
                }}
              />
            </label>
            {files && (
              <HFlex
                p-12-rl
                p-10-t
                g-12
                ovf-s-d
                style={{
                  marginTop: "-10px",
                }}
              >
                {files.map((file, i) => {
                  return (
                    <VFlex key={i} rel>
                      <Image
                        width={56}
                        height={56}
                        bdr-16
                        src={URL.createObjectURL(file)}
                        onClick={() => {
                          setPhotosView(true);
                        }}
                      />
                      <Absolute
                        top={-9}
                        right={-9}
                        bottom={"auto"}
                        left={"auto"}
                        width={24}
                        height={24}
                        onClick={() => {
                          removeFile(i);
                        }}
                      >
                        <Image bdr-16 src={`/image/delete.png`} />
                      </Absolute>
                    </VFlex>
                  );
                })}
              </HFlex>
            )}
          </HFlex>
        </VFlex>
        <VFlex g-34 p-24-brl>
          <TextArea
            placeholder="추가 요청사항이 있으시면 작성해 주세요 (예 : 전체적인 위생미용이 필요해요. 발 잡으면 불편해합니다.)"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            p-16
            bc-t1
            bdr-16
            t-16-s8
            style={{
              height: 119,
              overflowY: "scroll",
              border: "none",
            }}
          />
          <VFlex a-st p-16-b>
            <VFlex
              f-1
              c-c
              p-20-tb
              bdr-16
              t-18-ww
              bc-pt
              onClick={() => {
                onComplete(memo, files);
              }}
            >
              저장
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default NoteBottomSheet;
