import { HTMLAttributes, useEffect, useRef } from 'react';

import _ from 'lodash';
import {
  ColorProps,
  FontProps,
  LineProps,
  MiscellaneousProps,
  propsToClasses,
  restOfProps,
} from './index_generated';
import './layout.scss';

type TextProps = {
  classes?: string[];
  width?: number;
  height?: number;
  autoFocus?: boolean;
} & HTMLAttributes<HTMLSpanElement> &
  ColorProps &
  FontProps &
  LineProps &
  MiscellaneousProps;

const Text = ({
  classes = [],
  children,
  width,
  height,
  style,
  contentEditable,
  autoFocus,
  ...props
}: TextProps) => {
  const spanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (contentEditable && autoFocus) {
      spanRef.current?.focus();
    }
  }, [spanRef, contentEditable, autoFocus]);

  return (
    <span
      ref={spanRef}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          setTimeout(() => spanRef.current?.blur(), 0);
        }
      }}
      contentEditable={contentEditable}
      suppressContentEditableWarning={true}
      className={_.compact([...propsToClasses(props), ...classes]).join(' ')}
      style={{
        ...style,
        ...(width ? { width: `${width}px` } : {}),
        ...(height ? { height: `${height}px` } : {}),
      }}
      {...restOfProps(props)}
    >
      {children}
    </span>
  );
};

export default Text;
