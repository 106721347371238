import { useNavigate } from "react-router-dom";

import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import VFlex from "../../layouts/VFlex";
import SvgIcon, { SvgIconType } from "../../svg";

import "../../css/View.scss";

import { useAppLinkContext } from "../../modules/useAppLink";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../../common/hooks/useAmplitudeTrack";

export default function Coparent() {
  const navigate = useNavigate();
  const { openAppLink } = useAppLinkContext();
  const { trackEvent } = useAmplitudeTrack();

  return (
    <>
      <VFlex p-24-rl ovf-h style={{ paddingBottom: "160px" }}>
        <HFlex p-16-tb>
          <SvgIcon
            icon={SvgIconType.Back}
            size={24}
            onClick={() => navigate(-1)}
          />
        </HFlex>
        <VFlex f-1 ovf-s>
          <HFlex m-16-t>
            <HFlex
              p-8-rl
              p-2-tb
              bdr-8
              style={{ background: "rgba(39, 189, 99, 0.12)" }}
            >
              <Text t-14-500-pt>NEW</Text>
            </HFlex>
          </HFlex>

          <Text
            t-24-700-s8
            m-12-t
            style={{ whiteSpace: "pre-wrap" }}
          >{`공동양육자 초대하고 가족들과\n함께 케어해요!`}</Text>
          <Text
            t-16-500-s6
            m-16-t
            style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all" }}
          >{`RFID 등록으로 법적 보호자 인증을 하면 가족 혹은 가까운 지인과 케어 서비스 정보를 공유하고 함께 관리할 수 있어요.`}</Text>

          <HFlex g-6 m-40-t>
            <Text t-24-700-ac>Q.</Text>
            <Text t-24-700-s8>무엇을 함께할 수 있나요?</Text>
          </HFlex>

          <VFlex m-28-t>
            <Image
              src="/image/coparent/share_care_service_data.png"
              height={160}
              bc-t3
              bdr-16
              m-28-b
            />
            <Text t-22-700-s8 m-12-b>
              케어 서비스 정보 공유
            </Text>
            <Text
              t-16-500-s6
              style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all" }}
            >{`케어 서비스 일정과 안전문진표를 통한 건강 상태, 그리고 알림장에 담긴 소중한 반려동물의 모습까지 공유할 수 있어요.`}</Text>
          </VFlex>

          <VFlex style={{ marginTop: "80px" }}>
            <Image
              src="/image/coparent/data_integration.png"
              height={160}
              bc-t3
              bdr-16
              m-28-b
            />
            <Text t-22-700-s8 m-12-b>
              반려동물 정보 통합
            </Text>
            <Text
              t-16-500-s6
              style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all" }}
            >{`동일한 반려동물의 정보가 여러 계정에 흩어져 있는 경우 공동양육기능을 통해 정보를 통합하여 관리할 수 있어요.`}</Text>
          </VFlex>

          <VFlex style={{ marginTop: "80px" }}>
            <Image
              src="/image/coparent/disconnect.png"
              height={160}
              bc-t3
              bdr-16
              m-28-b
            />
            <Text t-22-700-s8 m-12-b>
              연동해지
            </Text>
            <Text
              t-16-500-s6
              style={{ whiteSpace: "pre-wrap", wordBreak: "keep-all" }}
            >{`연동해지는 법적 보호자만이 할 수 있으며 해지 시 반려동물의 정보는 최초 등록한 계정에서만 확인할 수 있어요.`}</Text>
          </VFlex>
        </VFlex>
      </VFlex>
      <VFlex
        style={{
          width: "100%",
          inset: "auto auto 0 auto",
          padding: "24px 24px 40px 24px",
          position: "fixed",
          background:
            "linear-gradient(0deg, #FFF 80%, rgba(255, 255, 255, 0.00) 100%)",
        }}
      >
        <VFlex
          style={{ width: "100%" }}
          height={64}
          c-c
          bc-pt
          bdr-16
          t-18
          c-ww
          onClick={() => {
            trackEvent(amplitudeEvents.appDownloadBtnClick, {
              location: "coparent_detail",
            });
            openAppLink("coparent");
          }}
        >
          공동양육 시작하기
        </VFlex>
      </VFlex>
    </>
  );
}
