import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../common/api";
import { numberWithCommas } from "../../common/utils/format";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";

import SvgIcon, { SvgIconType } from "../../svg";

export default function InvoiceReceipt() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <VFlex f-1 as-st c-c>
      <VFlex
        style={{
          maxWidth: 480,
          width: "100%",
        }}
      >
        <HFlex a-c p-24 g-16>
          <SvgIcon
            size={24}
            icon={SvgIconType.Back}
            onClick={() => {
              navigate(-1);
            }}
          />
          <VFlex t-22-600-s8>카드 영수증</VFlex>
        </HFlex>

        <VFlex p-24>
          <VFlex g-24 bd-b-t2 p-24-b>
            <VFlex t-20-600-s8>결제 정보</VFlex>
            <VFlex g-10>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>카드종류/승인번호</VFlex>
                <VFlex t-16-gr5>.</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>카드번호</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>거래유형/할부</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>결제일시</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>상품명</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>예약번호</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
            </VFlex>
          </VFlex>

          <VFlex g-16 bd-b-t2 p-24-tb>
            <HFlex a-c j-b g-16>
              <VFlex g-2>
                <VFlex t-16-600-s6>승인</VFlex>
                <VFlex t-14-s4>.</VFlex>
              </VFlex>
              <VFlex t-16-s8>{`${numberWithCommas(0)}원`}</VFlex>
            </HFlex>
            <HFlex a-c j-b g-16>
              <VFlex g-2>
                <VFlex t-16-600-s6>부분 취소</VFlex>
                <VFlex t-14-s4>.</VFlex>
              </VFlex>
              <VFlex t-16-s8>{`-${numberWithCommas(0)}원`}</VFlex>
            </HFlex>
          </VFlex>

          <VFlex g-24 bd-b-t2 p-24-tb>
            <VFlex t-20-600-s8>총 결제 금액</VFlex>
            <VFlex g-10>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>과세 금액</VFlex>
                <VFlex t-16-s8>{`${numberWithCommas(0)}원`}</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>부가세</VFlex>
                <VFlex t-16-s8>{`${numberWithCommas(0)}원`}</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>면세 금액</VFlex>
                <VFlex t-16-s8>{`${numberWithCommas(0)}원`}</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>봉사료</VFlex>
                <VFlex t-16-s8>{`${numberWithCommas(0)}원`}</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-18-600-s8>결제 금액</VFlex>
                <VFlex t-18-600-gr5>{`${numberWithCommas(0)}원`}</VFlex>
              </HFlex>
            </VFlex>
          </VFlex>

          <VFlex g-24 bd-b-t2 p-24-tb>
            <VFlex t-20-600-s8>가맹점 정보</VFlex>
            <VFlex g-10>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>가맹점명</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>대표자명</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>사업자등록번호</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>가맹점 전화번호</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
              <HFlex a-c j-b g-16>
                <VFlex t-16-s4>가맹점 주소</VFlex>
                <VFlex t-16-s8>.</VFlex>
              </HFlex>
            </VFlex>
          </VFlex>

          <VFlex
            g-24
            p-24-t
            style={{
              paddingBottom: 46,
            }}
          >
            <VFlex g-12>
              <VFlex t-18-600-s4>결제 대행사 정보</VFlex>
              <VFlex l-n t-16-s4>
                (주)섹타나인 | 대표자명: 김대일 | 사업자등록번호 1138610017 |
                주소: 서울특별시 강남구 논현로 201(SPC2023) | 승인관련문의:
                02-2040-1004
              </VFlex>
            </VFlex>
            <VFlex l-n t-14-s4>
              부가가치세법 제33조, 제36조 및 제46조에 따라 신용카드매출전표 등을
              발급한 경우에는 세금계산서를 발급(교부)할 수 없습니다.
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}
