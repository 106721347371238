import { BottomSheet } from "react-spring-bottom-sheet";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";

import "react-spring-bottom-sheet/dist/style.css";
import { CardCompanies } from "../common/types";

function InvoiceCardBottomSheet({
  open,
  onDismiss,
  onComplete,
  cardCompanies,
  cardName,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: (card: string, code: string) => void;
  cardCompanies: CardCompanies[];
  cardName: string;
}) {
  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          카드사 선택
        </Text>
      </HFlex>
      <VFlex
        p-16-t
        p-24-rl
        p-32-b
        g-8
        style={{
          flexWrap: "wrap",
          flexDirection: "unset",
        }}
      >
        {cardCompanies.map((card) => {
          return (
            <VFlex
              key={card.code}
              a-c
              p-16-tb
              bdr-16
              width={"calc(100%/2 - 4px)"}
              bd-t5={cardName !== card.name}
              bd-gr5={cardName === card.name}
              t-16-s8={cardName !== card.name}
              t-16-gr5={cardName === card.name}
              style={{
                background:
                  cardName === card.name ? "rgba(39, 189, 99, 0.04)" : "none",
              }}
              onClick={() => {
                onComplete(card.name, card.code);
              }}
            >
              {card.name}
            </VFlex>
          );
        })}
      </VFlex>
    </BottomSheet>
  );
}

export default InvoiceCardBottomSheet;
