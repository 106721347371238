import useAmplitudeTrack, {
  amplitudeEvents,
} from "../common/hooks/useAmplitudeTrack";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";

import { useAppLinkContext } from "../modules/useAppLink";

export enum TopBannerLocation {
  reservation = "reservation",
  serviceNote = "service_note",
  questionnaireResult = "questionnaire_result",
}

export default function TopBanner({
  location,
}: {
  location: TopBannerLocation;
}) {
  const { openAppLink } = useAppLinkContext();
  const { trackEvent } = useAmplitudeTrack();

  return (
    <HFlex
      f-1
      onClick={() => {
        trackEvent(amplitudeEvents.appDownloadBtnClick, {
          location: `top_banner_${location}`,
        });
        openAppLink(`top_banner_${location}`);
      }}
    >
      <Image
        bc-t2
        src="/image/app_download_banner.png"
        style={{ width: "100%" }}
      />
    </HFlex>
  );
}
