import * as amplitude from "@amplitude/analytics-browser";

export default function useAmplitudeTrack() {
  const identifyObj = new amplitude.Identify();

  const setIdentifyObj = (key: string, value: any) => {
    identifyObj.set(key, value);
    amplitude.identify(identifyObj);
  };

  const trackEvent = (event: string, properties?: Record<string, any>) => {
    amplitude.track(event, { ...properties, platform: "PUW" });
  };

  return { trackEvent, setIdentifyObj };
}

export const amplitudeEvents = {
  appDownloadBSDissmiss: "user_platform_app_download_bs_dismiss",
  appDownloadBtnClick: "user_platform_app_download_btn_click",
  reservationLand: "user_platform_reservation_land",
  reservationVisitConfirm: "user_platform_reservation_visit_confirm",
  inquiryLand: "user_platform_inquiry_land",
  inquiryComplete: "user_platform_inquiry_complete",
  serviceNoteLand: "user_platform_service_note_land",
  serviceNoteShare: "user_platform_service_note_share",
  questionnaireLand: "user_platform_questionnaire_land",
  questionnaireFillIn: "user_platform_questionnaire_fill_in",
  questionnaireComplete: "user_platform_questionnaire_complete",
  serviceReviewLand: "user_platform_service_review_land",
  serviceReviewComplete: "user_platform_service_review_complete",
  progressStatus: "user_platform_progress_status",
  // 결제
  payDetailLand: "user_platform_land_pay_detail",
  payResultLand: "user_platform_land_pay_result",
  cancleRes: "user_platform_cancel_res",
  cancleResClick: "user_platform_click_cancel_res",
  viewCardReceiptClick: "user_platform_click_view_card_receipt",
  pawdlyPayClick: "user_platform_click_pawdly_pay",
  addCardClick: "user_platform_click_add_card",
  cardPayClick: "user_platform_click_card_pay",
  doPayClick: "user_platform_click_do_pay",
  doPayDepositClick: "user_platform_click_do_pay_deposit",
};
