import { useNavigate } from "react-router-dom";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";

import _ from "lodash";

import SvgIcon, { SvgIconType } from "../../svg";

export default function InvoiceCancel() {
  const navigate = useNavigate();

  return (
    <VFlex f-1 as-st c-c>
      <VFlex
        style={{
          maxWidth: 480,
          width: "100%",
        }}
      >
        <HFlex
          p-16-b
          p-24-trl
          style={{
            justifyContent: "end",
          }}
          onClick={() => {
            // TODO
            // window.location.href = 'kakaotalk://inappbrowser/close';
            // <a onclick="window.close(); location.href = 'kakaotalk://inappbrowser/close'; return false;">창 닫기</a>
          }}
        >
          <SvgIcon size={24} icon={SvgIconType.Close} />
        </HFlex>

        <VFlex a-c g-16 m-24-rl p-24-b bd-b-t4>
          <SvgIcon size={56} icon={SvgIconType.Alert} />
          <VFlex a-c g-4>
            <Text t-22-600-s8>결제 실패</Text>
            <VFlex a-c t-16-s6>
              <Text>카드 결제가 완료되지 못했습니다.</Text>
              <Text>다시 시도해주세요.</Text>
            </VFlex>
          </VFlex>
        </VFlex>

        <VFlex a-c p-24 t-16-rd1>
          [error004] 기타오류 (통장 잔액부족)
        </VFlex>

        <VFlex
          a-c
          t-16-s4
          style={{
            paddingTop: 300,
            paddingBottom: 150,
          }}
        >
          <Text>문의가 있을 경우,</Text>
          <Text>1:1문의에 남겨주시면 신속히 해결해드리겠습니다.</Text>
        </VFlex>
      </VFlex>

      <VFlex
        p-16-tb
        p-24-rl
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000000,
        }}
      >
        <VFlex
          f-1
          c-c
          p-16-tb
          bdr-16
          t-18-600-ww
          bc-pt
          onClick={() => navigate(-1)}
        >
          다시 결제하기
        </VFlex>
      </VFlex>
    </VFlex>
  );
}
