import _ from "lodash";
import { HTMLAttributes } from "react";
import {
  BackgroundColorProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";

type DividerProps = {
  classes?: string[];
  width?: number | string;
  height?: number | string;
} & HTMLAttributes<HTMLDivElement> &
  BackgroundColorProps;

const Divider = ({
  classes = [],
  children,
  width,
  height,
  style,
  ...props
}: DividerProps) => {
  return (
    <div
      className={_.compact([
        ...propsToClasses(props),
        "divider",
        ...classes,
      ]).join(" ")}
      style={{
        ...style,
        ...(width
          ? _.isString(width)
            ? { width }
            : { width: `${width}px` }
          : {}),
        ...(height
          ? _.isString(height)
            ? { height }
            : { height: `${height}px` }
          : {}),
      }}
      {...restOfProps(props)}
    >
      {children}
    </div>
  );
};

export default Divider;
