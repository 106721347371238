import { useEffect } from "react";
import { Installments } from "../common/types";
import { BottomSheet } from "react-spring-bottom-sheet";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";

import "react-spring-bottom-sheet/dist/style.css";

function InvoiceInstallmentBottomSheet({
  open,
  onDismiss,
  onComplete,
  installments,
  installment,
  setInstallment,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: (card: string) => void;
  installments: Installments[];
  installment?: string;
  setInstallment: React.Dispatch<React.SetStateAction<string>>;
}) {
  useEffect(() => {
    if (installment === "") {
      setInstallment("일시불");
    }
  }, [installment]);

  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          할부 선택
        </Text>
      </HFlex>
      <VFlex
        p-16-t
        p-24-rl
        p-32-b
        g-8
        style={{
          flexWrap: "wrap",
          flexDirection: "unset",
        }}
      >
        {installments.map((value) => {
          return (
            <VFlex
              key={value.value}
              a-c
              p-16-tb
              bdr-16
              width={"calc(100%/2 - 4px)"}
              bd-t5={installment !== value.name}
              bd-gr5={installment === value.name}
              t-16-s8={installment !== value.name}
              t-16-gr5={installment === value.name}
              style={{
                background:
                  installment === value.name
                    ? "rgba(39, 189, 99, 0.04)"
                    : "none",
              }}
              onClick={() => {
                onComplete(value.name);
              }}
            >
              {value.name}
            </VFlex>
          );
        })}
      </VFlex>
    </BottomSheet>
  );
}

export default InvoiceInstallmentBottomSheet;
