import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../common/api";

export default function Payment() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (window.location.href.split("payment?")[1]) {
          const res = await api.get(
            `/ext/payment/approval?${window.location.href.split("payment?")[1]}`
          );

          setLoading(true);
          await navigate(
            `/invoicecomplete?key=${res.reservation?.reservationNumber}`
          );
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return <></>;
}
