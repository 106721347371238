import { ChangeEvent, ReactElement, useCallback } from 'react';

function FileUpload({ id, onLoad }: { id: string; onLoad: (file: File[]) => void }): ReactElement {
  const photoUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const files: File[] = Array.from(e.target.files as FileList);
      function readAndPreview(file: File) {
        if (/\.(jpe?g|png|gif)$/i.test(file.name)) {
          const reader = new FileReader();
          reader.onloadend = async () => {
            onLoad?.(files);
          };
          reader.readAsDataURL(file);
        }
      }
      if (files) {
        Array.prototype.forEach.call(files, readAndPreview);
      }
    },
    [onLoad]
  );

  return (
    <input
      id={id}
      type="file"
      multiple={true}
      onChange={photoUpload}
      onClick={(e: any) => {
        (e.target as HTMLInputElement).value = '';
      }}
    />
  );
}
export default FileUpload;
