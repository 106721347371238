import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import qs from 'qs';
import axios from 'axios';

function KakaoNavi() {
  const { Kakao } = window;
  let location = useLocation();
  let address = location.state.address;

  useEffect(() => {
    if (!Kakao.isInitialized()) {
      Kakao.init(process.env.REACT_APP_KAKAO_KEY);
    }

    const data = qs.stringify({
      query: address,
      page: 1,
      size: 10,
    });

    axios
      .get(`https://dapi.kakao.com/v2/local/search/address.json?${data}`, {
        headers: {
          Authorization: `KakaoAK 2aedc06e3fb246033130c4c1b800c45c`,
          'Content-Type': 'application/json;charset=UTF-8',
        },
      })
      .then((result) => {
        let response = result.data.documents[0].road_address;
        return response;
      })
      .then((response) => {
        navi(response);
      });
  }, []);

  const navi = (latlng) => {
    if (Kakao.isInitialized()) {
      Kakao.Navi.start({
        name: latlng.address_name,
        x: Number(latlng.x),
        y: Number(latlng.y),
        coordType: 'wgs84',
      });
    }
  };

  return <div></div>;
}

export default KakaoNavi;
