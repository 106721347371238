import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import _ from "lodash";
import api from "../common/api";

import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";

export default function Landing() {
  const navigate = useNavigate();
  const [datas, setDatas] = useState<any[]>();

  useEffect(() => {
    (async () => {
      const data = await api.get(`/rest/group/1/reservation/key`);
      if (data) {
        setDatas(data);
      }
    })();
  }, []);

  return (
    <>
      {window.location.href.includes("inquiry.pawdlers.com") ? (
        <VFlex
          style={{
            position: "relative",
            height: "100vh",
          }}
        >
          <VFlex
            t-28-600
            a-c
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            사용자 접근 불가 페이지입니다.
          </VFlex>
        </VFlex>
      ) : (
        <VFlex p-20>
          <VFlex t-28-600 p-20-tb>
            예약 상태 조회 😎
          </VFlex>
          <HFlex g-10 style={{ flexWrap: "wrap" }}>
            {_.entries(datas).map(([key, value]) => {
              return (
                value.length > 1 && (
                  <VFlex key={key} bdr-16 bd-pl width={150}>
                    <Text
                      p-20
                      bd-b-pl
                      t-16-600
                      t-16-rd1={key === "미방문" || key === "예약 취소"}
                      t-16-yv3={key === "알림장" || key === "마음 전달 하기"}
                    >
                      {key}
                    </Text>
                    {value.map(
                      (
                        item: {
                          id: number;
                          token: string;
                          type: string;
                        },
                        i: number
                      ) => {
                        return (
                          <VFlex
                            key={i}
                            hover
                            clickable
                            onClick={() => {
                              navigate(
                                `/${
                                  key === "알림장" || key === "마음 전달 하기"
                                    ? "servicenote"
                                    : "reservation"
                                }?key=${item.token}`
                              );
                            }}
                          >
                            <Text t-14 p-10-tb p-20-l>
                              {item.token}
                            </Text>
                          </VFlex>
                        );
                      }
                    )}
                  </VFlex>
                )
              );
            })}
          </HFlex>
        </VFlex>
      )}
    </>
  );
}
