import useAmplitudeTrack, {
  amplitudeEvents,
} from "../common/hooks/useAmplitudeTrack";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";

import { useAppLinkContext } from "../modules/useAppLink";

export enum FloatingKind {
  Inquriy = "inquiry",
  Questionnaire = "questionnaire",
}

const FLOATING_CONTENTS = [
  {
    kind: FloatingKind.Inquriy,
    title: "앱으로 더 편리하게 문의해보세요",
    linkLabel: "앱으로 문의하기",
  },
  {
    kind: FloatingKind.Questionnaire,
    title: "앱으로 더 편리하게 작성해보세요",
    linkLabel: "앱으로 작성하기",
  },
];

export default function FloatingBanner({
  kind,
  closeBanner,
}: {
  kind: FloatingKind;
  closeBanner: () => void;
}) {
  const { openAppLink } = useAppLinkContext();
  const contents = FLOATING_CONTENTS.find((c) => c.kind === kind);
  const { trackEvent } = useAmplitudeTrack();

  return (
    <HFlex
      f-1
      height={80}
      bdr-16
      sdw-6
      a-c
      j-b
      p-16-rl
      style={{
        width: "100%",
        background: "rgba(26, 28, 27, 0.80)",
        boxSizing: "border-box",
      }}
    >
      <HFlex>
        <VFlex
          width={48}
          height={48}
          c-c
          bc-ac
          style={{ borderRadius: "10.5px" }}
          m-12-r
        >
          <Image src="/image/app_logo.svg" size={34} />
        </VFlex>

        <VFlex clickable>
          <Text t-16-600-ww>{contents?.title}</Text>
          <Text
            t-14-500-ww
            style={{ textDecorationLine: "underline" }}
            onClick={() => {
              trackEvent(amplitudeEvents.appDownloadBtnClick, {
                location: `floating_banner_${kind}`,
              });
              openAppLink(`floating_banner_${kind}`);
            }}
          >
            {contents?.linkLabel}
          </Text>
        </VFlex>
      </HFlex>

      <VFlex clickable onClick={closeBanner}>
        <Image src="/image/close.svg" size={24} />
      </VFlex>
    </HFlex>
  );
}
