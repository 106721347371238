import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";

import { format } from "date-fns";
import { BottomSheet } from "react-spring-bottom-sheet";

import SvgIcon, { SvgIconType } from "../svg";

import "react-spring-bottom-sheet/dist/style.css";

function QuestionnaireHistoryBottomSheet({
  history,
  open,
  onDismiss,
  onComplete,
}: {
  history: any[];
  pet: any;
  subscriber: any;
  open: boolean;
  onDismiss: () => void;
  onComplete: (item?: any) => void;
}) {
  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          이전 문진표를 기준으로 생성 할까요?
        </Text>
      </HFlex>
      <VFlex p-24>
        {history.slice(0, 1).map((item, i) => (
          <HFlex
            key={i}
            a-c
            g-16
            height={76}
            onClick={() => {
              onComplete?.(item);
            }}
          >
            <SvgIcon size={24} icon={SvgIconType.QuestionnaireIcon} />
            <Text t-18-g5>
              {format(new Date(item.actualSentDate * 1000), "yyyy.MM.dd")}에
              작성한 문진표
            </Text>
          </HFlex>
        ))}
        <HFlex
          a-c
          g-16
          height={76}
          onClick={() => {
            onComplete?.();
          }}
        >
          <SvgIcon size={24} icon={SvgIconType.QuestionnaireNewIcon} />
          <Text t-18-g5>새로 작성하기</Text>
        </HFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default QuestionnaireHistoryBottomSheet;
