import React from "react";
import { useMediaQuery } from "react-responsive";

/*
    Mobile (Smartphone) max-width: 480px
    Low Resolution Tablets and ipads max-width: 767px
    Tablets Ipads portrait mode max-width:1024px
    Desktops max-width:1280px
    Huge size (Larger screen) max-width: 1281px and greater
* */

const MOBILE = ({ children }) => {
  const isMobile = useMediaQuery({
    query: "(max-width:480px)",
  });
  return <React.Fragment>{isMobile && children}</React.Fragment>;
};
const TABLET = ({ children }) => {
  const isTablet = useMediaQuery({
    query: "(min-width:481px) and (max-width:1023px)",
  });
  return <React.Fragment>{isTablet && children}</React.Fragment>;
};

const PC = ({ children }) => {
  const isPc = useMediaQuery({
    query: "(min-width:1024px)",
  });
  return <React.Fragment>{isPc && children}</React.Fragment>;
};

export { MOBILE, TABLET, PC };
