import { BottomSheet } from "react-spring-bottom-sheet";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";

import "react-spring-bottom-sheet/dist/style.css";

import SvgIcon, { SvgIconType } from "../svg";

function VisitConfirmBottomSheet({
  open,
  onDismiss,
  onComplete,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: () => void;
}) {
  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          방문을 확정하시겠어요?
        </Text>
        <SvgIcon
          size={24}
          icon={SvgIconType.Close}
          onClick={() => onDismiss()}
        />
      </HFlex>
      <VFlex p-24-rl g-24>
        <Text f-1 t-16-gd>
          방문 확정은 업체 스케줄 관리에 큰 도움을 줄 수 있어요
        </Text>
        <VFlex a-st p-40-b>
          <HFlex g-8>
            <VFlex
              f-1
              c-c
              p-20-tb
              bdr-16
              t-18-s7
              bc-gl
              onClick={() => {
                onDismiss();
              }}
            >
              취소
            </VFlex>
            <VFlex
              f-1
              c-c
              p-20-tb
              bdr-16
              t-18-ww
              bc-pt
              onClick={() => {
                onComplete();
              }}
            >
              방문 확정하기
            </VFlex>
          </HFlex>
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default VisitConfirmBottomSheet;
