export const shareKakao = () => {
  if (window.Kakao) {
    const kakao = window.Kakao;

    if (!kakao.isInitialized()) {
      kakao.init('60773ec91f20d312d8bbdadb8ab2c4b5');
    }

    kakao.Link.sendCustom({
      templateId: 94745,
    });
  }
};
