import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import api from "../../common/api";
import { invoiceStatusString, statusString } from "../../common/constants";
import useAmplitudeTrack, {
  amplitudeEvents,
} from "../../common/hooks/useAmplitudeTrack";

import { RequestFetch, RESERVATION_STATUS } from "../../model/Request/Request";
import { useAppLinkContext } from "../../modules/useAppLink";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Text from "../../layouts/Text";
import Divider from "../../layouts/Divider";
import Absolute from "../../layouts/Absolute";

import KakaoMapContainer from "../../components/KakaoMapContainer";
import TopBanner, { TopBannerLocation } from "../../components/TopBanner";
import Timer from "../../components/Timer";
import VisitConfirmBottomSheet from "../VisitConfirmBottomSheet";
import CancelBottomSheet from "../CancelBottomSheet";

import _ from "lodash";
import { toast } from "react-hot-toast";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

import SvgIcon, { SvgIconType } from "../../svg";
import "../../css/View.scss";

export default function Reservation() {
  const { state } = useLocation();
  const { trackEvent, setIdentifyObj } = useAmplitudeTrack();
  const navigate = useNavigate();
  const { initProperties, openAppLink } = useAppLinkContext();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key") || searchParams.get("revNo");
  const { changeReservationStatus } = RequestFetch();
  const [warningMessage, setWarningMessage] = useState<string>();
  const [visitConfirmBottomSheetOpened, setVisitConfirmBottomSheetOpened] =
    useState(false);
  const [cancelBottomSheetOpened, setCancelBottomSheetOpened] = useState(false);
  const [stageVisible, setStageVisible] = useState<boolean>(state);

  const [content, setContent] = useState<{
    status: number;
    cancelMemo: string;
    displayId: string;
    pet: any;
    group: any;
    startDate: number;
    endDate: number;
    memo: string;
    productList: any[];
    subscriber: any;
    beautyQuestionnaire: boolean;
    time: string[];
    inquiredReservationDate: number;
    requestMemo?: string;
    inquiredAttachments?: [
      {
        id: number;
        path: string;
        size: string;
      }
    ];
    attachments: [
      {
        path: string;
        size: string;
      }
    ];
    deposit: number;
    depositExpired: number;
    invoiceSummaries: {
      expiredDate: number;
      requestCanceled: number;
      invoiceSummaries: [
        {
          amountSummary: string;
          dateSummary: string;
          id: number;
          status: string;
          type: string;
        }
      ];
      totalCount: number;
    };
    userCancelNote: string;
    partnerCancelNote?: string;
  }>();
  const [questionnaireId, setQuestionnaireId] = useState();
  const [group, setGroup] = useState<{
    inquiredReservation: boolean;
    notice: string;
    address: {
      address: string;
      id: number;
      latitude: number;
      longitude: number;
    };
    usedkakoApi: boolean | undefined;
  }>();
  const [notice, setNotice] = useState<boolean>();
  const [history, setHistory] = useState<any[]>([]);
  const [invoice, setInvoice] = useState<{
    amountSummary: string;
    dateSummary: string;
    id: number;
    status: string;
    type: string;
  }>();

  useEffect(() => {
    (async () => {
      const content = await api.get(`/ext?key=${key}`);
      setContent(content);
      initProperties({
        groupId: content.group.id,
        petId: content.pet.id,
      });
      setIdentifyObj("petId", content.pet.id);
      setIdentifyObj("groupId", content.group.id);
      trackEvent(amplitudeEvents.reservationLand, {
        reservationNumber: key,
        status: getStatusString(content?.status),
      });
      const group = await api.get(`/rest/group/${content.group.id}`);
      setGroup(group);
      const notice = await api.get(`/ext/reservation/notice?key=${key}`);
      setNotice(notice);
      const warningMessage = await api.get(
        `/rest/group/${content.group.id}/warning-message`
      );
      setWarningMessage(warningMessage.message);
      const questionnaires = await api.get(
        `/rest/questionnaires?groupId=${content.group.id}`
      );
      setQuestionnaireId(
        questionnaires.filter((q: any) => q.groupEnabled)?.[0]?.id
      );
      const history = await api.get(
        `/ext/questionnaire/history?groupId=${group.id}&petId=${content.pet.id}&userId=${content.subscriber.id}`
      );
      setHistory(history);
    })();
  }, [key]);

  useEffect(() => {
    if (content?.invoiceSummaries) {
      setInvoice(content?.invoiceSummaries.invoiceSummaries[0]);
    }
  }, [content]);

  const statusStrings = [
    // 순서 변경 하지 말 것 (db순서와 동일 해야함)
    [
      "문의 진행중",
      `${content?.group?.title}에서 문의를 확인하고 있어요`,
      "icon_ongoing",
      1,
      "문의등록 완료",
      "업체에서 문의를 확인하고 있어요",
    ],
    ["문의 종료", "문의가 종료되었습니다", "icon_cancel", 1],
    [
      "예약 진행중",
      "예약금 결제 완료시 예약이 확정됩니다.",
      "icon_pending",
      1,
      "예약 진행중",
      "예약금 결제 완료시 예약이 확정됩니다.",
    ],
    [
      "예약 확정",
      "예약이 확정되었습니다.",
      "icon_check",
      1,
      "예약확정 완료",
      "정보를 확인하고 방문여부를 알려주세요",
    ],
    [
      "이용 완료",
      "이용이 완료되었어요",
      "icon_check",
      2,
      "서비스 이용완료",
      "이용해주셔서 감사합니다",
    ],
    ["예약 취소", "예약이 취소되었습니다", "icon_cancel", 1],
    [
      "예약 취소",
      `${
        invoice?.status === "EXPIRED"
          ? "결제 기한 만료"
          : invoice?.status === "CANCELED_REQUEST"
          ? "업체의 예약 취소"
          : invoice?.status === "U_CANCELED"
          ? "고객의 예약 취소"
          : "예약이 취소되었습니다"
      }`,
      "icon_cancel",
      1,
    ],
    ["미방문", "미방문 처리되었습니다", "icon_cancel", 1],
    ["삭제", "", "icon_cancel", 1],
    ["삭제", "", "icon_cancel", 1],
    [
      "방문 확정",
      `${content?.group?.title} 방문이 확정되었어요`,
      "icon_check",
      1,
      "방문확정 완료",
      "예약일에 만나요!",
    ],
    ["임시저장", "", "icon_cancel", 1],
    ["가예약", "", "icon_cancel", 1],
    ["예약 취소", "결제 기한 만료", "icon_cancel", 1],
  ];
  const getStatusString = (status: number) => {
    return statusStrings[status][0] as string;
  };
  const getNoticeType = (status: number) => {
    return statusStrings[status][3] as number;
  };
  const getStatusDes1 = (status: number) => {
    return statusStrings[status][4] as string;
  };
  const getStatusDes2 = (status: number) => {
    return statusStrings[status][5] as string;
  };

  const handleConfirmVisit = async () => {
    const result = await changeReservationStatus(
      key,
      RESERVATION_STATUS.CONFIRM_VISIT
    );
    setVisitConfirmBottomSheetOpened(false);

    if (result.apiCode === "A01") {
      toast.success("방문을 확정하였습니다");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error(result.message);
    }
  };

  const statusArr = [
    {
      id: 99,
      text: "문의 등록",
    },
    {
      id: 0,
      text: "업체 확인중",
    },
    {
      id: 2,
      text: "예약 진행중",
    },
    {
      id: 3,
      text: "예약 확정",
    },
    {
      id: 10,
      text: "방문 확정",
    },
    {
      id: 4,
      text: "이용 완료",
    },
  ];

  if (_.isEmpty(content)) {
    return <></>;
  }

  return (
    <>
      <TopBanner location={TopBannerLocation.reservation} />
      {stageVisible ? (
        <VFlex
          abs
          bc-ww
          p-24-t
          p-24-rl
          style={{
            maxWidth: 480,
            minHeight: 900,
            width: "100%",
            zIndex: 1000001,
          }}
        >
          <VFlex t-24-600 p-16-t>
            <VFlex l-n>{getStatusDes1(content?.status)}</VFlex>
            <VFlex l-n>{getStatusDes2(content?.status)}</VFlex>
          </VFlex>
          <VFlex a-c p-16>
            {getStatusString(content?.status) === "예약 확정" ||
            getStatusString(content?.status) === "방문 확정" ? (
              <Image size={300} src={`/image/reservationstage.png`} />
            ) : (
              <Image size={300} src={`/image/reservationstage_done.png`} />
            )}
            {questionnaireId &&
              // getStatusString(content?.status) === "문의 진행중" ||
              (getStatusString(content?.status) === "예약 확정" ||
                getStatusString(content?.status) === "방문 확정") && (
                <HFlex
                  m-30-t
                  a-c
                  onClick={() => {
                    if (content.beautyQuestionnaire) {
                      navigate("/questionnaireresult", {
                        state: {
                          sourceQuestionnaire: history
                            .slice(0, 1)
                            .map((item) => {
                              return item;
                            }),
                        },
                      });
                    } else {
                      navigate(`/questionnaire`, {
                        state: {
                          group: content?.group,
                          pet: content?.pet,
                          subscriber: content?.subscriber,
                          key,
                          questionnaireId,
                        },
                      });
                    }
                  }}
                >
                  <VFlex t-18-600-s4>
                    {content.beautyQuestionnaire
                      ? "작성한 안전 문진표 확인하기"
                      : "우리 아이 건강 상태 점검하기"}
                  </VFlex>
                  <SvgIcon size={24} icon={SvgIconType.ArrowRight} />
                </HFlex>
              )}

            {questionnaireId &&
              getStatusString(content?.status) === "이용 완료" &&
              content.beautyQuestionnaire && (
                <HFlex
                  m-30-t
                  a-c
                  onClick={() => {
                    navigate("/questionnaireresult", {
                      state: {
                        sourceQuestionnaire: history.slice(0, 1).map((item) => {
                          return item;
                        }),
                      },
                    });
                  }}
                >
                  <VFlex t-18-600-s4>작성한 문진표 확인하기</VFlex>
                  <SvgIcon size={24} icon={SvgIconType.ArrowRight} />
                </HFlex>
              )}
          </VFlex>

          <HFlex p-24-tb g-16>
            <VFlex
              style={{
                padding: "5px 0",
              }}
            >
              {statusArr.map((v, i) => {
                return (
                  <VFlex a-c key={i}>
                    <VFlex
                      rel
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: 12,
                        background:
                          content?.status === v.id ? "#27BD63" : "#D7DCDA",
                      }}
                    >
                      <VFlex
                        style={{
                          position: "absolute",
                          left: 3,
                          top: 3,
                          width: 6,
                          height: 6,
                          borderRadius: 6,
                          background: "#fff",
                        }}
                      />
                    </VFlex>
                    {i !== statusArr.length - 1 && (
                      <VFlex
                        style={{
                          width: 1,
                          height: 44,
                          background: "#D7DCDA",
                        }}
                      />
                    )}
                  </VFlex>
                );
              })}
            </VFlex>
            <VFlex g-32>
              {statusArr.map((v) => {
                return (
                  <Text
                    key={v.id}
                    t-16-s2={v.id !== content?.status}
                    t-16-600-s8={v.id === content?.status}
                  >
                    {v.text}
                  </Text>
                );
              })}
            </VFlex>
          </HFlex>

          <VFlex
            a-st
            p-24
            height={112}
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 1000000,
            }}
          >
            <VFlex
              f-1
              c-c
              bdr-16
              t-18-ww
              bc-pt
              onClick={() => {
                setStageVisible(false);
                setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }, 0);
              }}
            >
              확인
            </VFlex>
          </VFlex>
          {/* E stage */}
        </VFlex>
      ) : (
        <VFlex f-1 as-st c-c style={{ backgroundColor: "#F2F5F4" }}>
          <VFlex
            style={{
              maxWidth: 480,
              width: "100%",
              paddingBottom: 180,
              // getStatusString(content?.status) === "예약 확정" ||
              // getStatusString(content?.status) === "이용 완료" ||
              // getStatusString(content?.status) === "미방문" ||
              // getStatusString(content?.status) === "예약 취소"
              //   ? "100px"
              //   : invoice?.status
              //   ? "190px"
              //   : 0,
            }}
          >
            <VFlex g-16 p-24>
              <HFlex a-c j-b height={48}>
                <SvgIcon width={128} height={28} icon={SvgIconType.Logo} />

                <HFlex
                  p-8-tb
                  p-16-rl
                  bd-t4
                  bdr-24
                  a-c
                  onClick={() => navigate("/coparent")}
                >
                  <Text t-14-500-s6>가족과 함께 관리</Text>
                </HFlex>
              </HFlex>
              <VFlex g-16>
                {questionnaireId &&
                  (getStatusString(content?.status) === "문의 진행중" ||
                    getStatusString(content?.status) === "예약 확정" ||
                    getStatusString(content?.status) === "방문 확정") &&
                  !content.beautyQuestionnaire && (
                    <VFlex
                      p-24
                      bdr-24
                      bc-ww
                      g-16
                      onClick={() => {
                        navigate(`/questionnaire`, {
                          state: {
                            group: content.group,
                            pet: content.pet,
                            subscriber: content.subscriber,
                            key,
                            questionnaireId,
                          },
                        });
                      }}
                    >
                      <HFlex a-c j-b>
                        <HFlex g-16 a-c>
                          <SvgIcon width={36} icon={SvgIconType.SafetyNote} />
                          <VFlex t-22-600>안전 문진표 작성하기</VFlex>
                        </HFlex>
                        <VFlex>
                          <SvgIcon
                            width={24}
                            icon={SvgIconType.ArrowRightBig}
                          />
                        </VFlex>
                      </HFlex>
                    </VFlex>
                  )}

                {questionnaireId &&
                  notice &&
                  getStatusString(content?.status) === "이용 완료" && (
                    <VFlex
                      p-24
                      bdr-24
                      bc-ww
                      g-16
                      onClick={() => {
                        navigate(`/servicenote?revNo=${key}`, {
                          state: true,
                        });
                      }}
                    >
                      <HFlex a-c j-b>
                        <HFlex g-16 a-c>
                          <SvgIcon width={36} icon={SvgIconType.ServiceNote} />
                          <VFlex t-22-600>새로운 알림장 도착</VFlex>
                        </HFlex>
                        <VFlex>
                          <SvgIcon
                            width={24}
                            icon={SvgIconType.ArrowRightBig}
                          />
                        </VFlex>
                      </HFlex>
                    </VFlex>
                  )}

                {content && (
                  <ReservationStatus
                    status={content.status}
                    title={content.group.title}
                    questionnaireId={questionnaireId ?? 0}
                    history={history}
                    beautyQuestionnaire={content.beautyQuestionnaire}
                    invoice={invoice}
                    userCancelNote={content.userCancelNote}
                    partnerCancelNote={content.partnerCancelNote ?? ""}
                    setStageVisible={setStageVisible}
                  />
                )}

                {content.invoiceSummaries && (
                  <VFlex p-24 bdr-24 bc-ww g-24>
                    <HFlex a-c>
                      <Text
                        t-20-600-s8
                      >{`결제 정보(${content?.invoiceSummaries.totalCount}건)`}</Text>
                    </HFlex>
                    <VFlex g-12>
                      <Text
                        t-18-600-s6={invoice?.status === "UNPAID"}
                        t-18-600-gr5={invoice?.status === "PAID"}
                        t-18-600-rd1={
                          invoice?.status !== "PAID" &&
                          invoice?.status !== "UNPAID"
                        }
                      >
                        {
                          invoiceStatusString.find(
                            (item) => item.status === invoice?.status
                          )?.text
                        }
                      </Text>
                      <HFlex g-16>
                        <VFlex
                          c-c
                          width={44}
                          height={44}
                          bc-t2
                          bdr-12
                          rel
                          ovf-v
                        >
                          <SvgIcon
                            size={22}
                            icon={
                              invoice?.type === "RESV_DEPOSIT"
                                ? SvgIconType.Deposit
                                : SvgIconType.CreditCard
                            }
                          />
                          {invoice?.status === "PAID" && (
                            // 결제 완료
                            <Absolute
                              top={"auto"}
                              right={-4}
                              bottom={-8}
                              left={"auto"}
                            >
                              <SvgIcon
                                size={24}
                                icon={SvgIconType.CheckCircleS9}
                              />
                            </Absolute>
                          )}

                          {(invoice?.status === "U_CANCELED" ||
                            invoice?.status === "B_CANCELED" ||
                            invoice?.status === "EXPIRED" ||
                            invoice?.status === "CANCELED_REQUEST") && (
                            // 결제 취소
                            <Absolute
                              top={"auto"}
                              right={-4}
                              bottom={-8}
                              left={"auto"}
                            >
                              <SvgIcon
                                size={24}
                                icon={SvgIconType.CheckCircleR1}
                              />
                            </Absolute>
                          )}
                        </VFlex>
                        <VFlex
                          f-1
                          g-8
                          clickable
                          onClick={() => {
                            navigate(`/invoice?key=${key}`, {
                              state: {},
                            });
                          }}
                        >
                          <HFlex a-c j-b>
                            <VFlex>
                              <VFlex t-14-600-s6>
                                {content.invoiceSummaries.invoiceSummaries[0]
                                  .type === "RESV_DEPOSIT"
                                  ? "예약금"
                                  : "서비스 금액"}
                              </VFlex>
                              <VFlex t-18-600-s8>
                                {
                                  content.invoiceSummaries.invoiceSummaries[0]
                                    .amountSummary
                                }
                              </VFlex>
                            </VFlex>
                            <SvgIcon size={24} icon={SvgIconType.ArrowRight} />
                          </HFlex>
                          <VFlex t-14-600-s4>
                            {
                              content.invoiceSummaries.invoiceSummaries[0]
                                .dateSummary
                            }
                          </VFlex>
                        </VFlex>
                      </HFlex>
                    </VFlex>
                  </VFlex>
                )}

                {getStatusString(content?.status) === "미방문" ||
                getStatusString(content?.status) === "예약 취소" ? (
                  <></>
                ) : (
                  <VFlex p-24 bdr-24 bc-ww g-24>
                    <HFlex g-16 a-c>
                      <SvgIcon icon={SvgIconType.Speaker} />
                      <Text t-22-600-s8>
                        {getNoticeType(content?.status) === 1
                          ? "예약 시 안내사항"
                          : "서비스 후 주의사항"}
                      </Text>
                    </HFlex>
                    <VFlex t-16-s4>
                      {getNoticeType(content?.status) === 1 ? (
                        <Text l-n>{group?.notice ? group?.notice : "-"}</Text>
                      ) : (
                        <Text l-n>{warningMessage}</Text>
                      )}
                    </VFlex>
                  </VFlex>
                )}

                <VFlex p-24 bdr-24 bc-ww g-24>
                  <Text t-22-600-s8>예약 정보</Text>
                  <HFlex g-16>
                    <Image
                      d-48
                      bdr-48
                      src={
                        content.pet?.profile_image?.path ??
                        "/image/profile_example.png"
                      }
                    />
                    <VFlex>
                      <Text t-14-500-g6>반려동물 정보</Text>
                      <Text t-18-600-s6 l-n>
                        {content?.pet?.name} &#40;{content?.pet?.species.name}
                        &#41;
                      </Text>
                    </VFlex>
                  </HFlex>
                  <Divider />
                  <HFlex g-16>
                    <HFlex c-c d-48 bdr-48 bc-g9 sized>
                      <SvgIcon icon={SvgIconType.Store} />
                    </HFlex>
                    <VFlex>
                      <Text t-14-g6>업체명</Text>
                      <Text t-18-600-s6 l-n>
                        {content?.group?.title}
                      </Text>
                    </VFlex>
                  </HFlex>
                  {content?.startDate ? (
                    <>
                      <HFlex g-16>
                        <HFlex c-c d-48 bdr-48 bc-g9 sized>
                          <SvgIcon icon={SvgIconType.Time} />
                        </HFlex>
                        <VFlex>
                          <Text t-14-g6>
                            {format(
                              new Date(content?.startDate * 1000),
                              "yyyy년 M월 d일 (EEE)",
                              {
                                locale: ko,
                              }
                            )}
                          </Text>
                          <Text t-18-600-s6>
                            {format(
                              new Date(content?.startDate * 1000),
                              "a h:mm",
                              {
                                locale: ko,
                              }
                            )}{" "}
                            &#126;{" "}
                            {format(new Date(content?.endDate * 1000), "h:mm", {
                              locale: ko,
                            })}
                          </Text>
                        </VFlex>
                      </HFlex>
                    </>
                  ) : (
                    <>
                      <HFlex g-16>
                        <HFlex c-c d-48 bdr-48 bc-g9 sized>
                          <SvgIcon icon={SvgIconType.Date} />
                        </HFlex>
                        <VFlex>
                          <Text t-14-g6>희망 날짜</Text>
                          <Text t-18-600-s6>
                            {format(
                              new Date(content?.inquiredReservationDate * 1000),
                              "M월 d일 EEEE",
                              {
                                locale: ko,
                              }
                            )}
                          </Text>
                        </VFlex>
                      </HFlex>
                      <HFlex g-16>
                        <HFlex c-c d-48 bdr-48 bc-g9 sized>
                          <SvgIcon icon={SvgIconType.Time} />
                        </HFlex>
                        <VFlex>
                          <Text t-14-g6>희망 시작 시간</Text>
                          <Text t-18-600-s6 l-n>
                            {content?.time?.map((value) => value).join(", ")}
                          </Text>
                        </VFlex>
                      </HFlex>
                    </>
                  )}
                  <HFlex g-16>
                    <HFlex c-c d-48 bdr-48 bc-g9 sized>
                      <SvgIcon icon={SvgIconType.Service} />
                    </HFlex>
                    <VFlex>
                      <Text t-14-g6>서비스명</Text>
                      <Text t-18-600-s6 l-n>
                        {content?.productList
                          ?.map((product) => product.name)
                          .join(", ")}
                      </Text>
                    </VFlex>
                  </HFlex>
                  <HFlex g-16>
                    <HFlex c-c d-48 bdr-48 bc-g9 sized>
                      <SvgIcon icon={SvgIconType.MemoColor} />
                    </HFlex>
                    <VFlex>
                      <VFlex>
                        <Text t-14-g6>요청사항</Text>
                        <Text
                          t-18-600-s6
                          style={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                          }}
                        >
                          {content?.memo || content.requestMemo || "-"}
                        </Text>
                      </VFlex>
                      <VFlex>
                        {(content.attachments ||
                          content.inquiredAttachments) && (
                          <HFlex g-8>
                            {(
                              content.attachments || content.inquiredAttachments
                            ).map((att, i) => {
                              return (
                                <VFlex
                                  key={i}
                                  p-12-t
                                  rel
                                  style={{
                                    display: i < 4 ? "block" : "none",
                                  }}
                                  onClick={() => {
                                    navigate("/reservationnotephotos", {
                                      state: { content },
                                    });
                                  }}
                                >
                                  <Image
                                    width={56}
                                    height={56}
                                    m-8-t
                                    bdr-16
                                    src={att.path}
                                  />
                                  {(
                                    content.attachments ||
                                    content.inquiredAttachments
                                  ).length > 4 &&
                                    i === 3 && (
                                      <VFlex
                                        abs
                                        a-c
                                        j-c
                                        width={"100%"}
                                        height={56}
                                        t-20-600-ww
                                        bdr-16
                                        style={{
                                          top: 20,
                                          left: 0,
                                          background: "rgba(0,0,0,0.3)",
                                        }}
                                      >
                                        +
                                        {(
                                          content.attachments ||
                                          content.inquiredAttachments
                                        ).length - 4}
                                      </VFlex>
                                    )}
                                </VFlex>
                              );
                            })}
                          </HFlex>
                        )}
                      </VFlex>
                    </VFlex>
                  </HFlex>
                  <HFlex
                    bd-h2
                    p-16-tb
                    bdr-16
                    c-c
                    onClick={() => {
                      trackEvent(amplitudeEvents.appDownloadBtnClick, {
                        location: "service_note_history_btn",
                      });
                      openAppLink("button_reservation");
                    }}
                  >
                    <Text t-16-600-h2>
                      {content?.pet?.name}의 지난 예약 내역 보기
                    </Text>
                  </HFlex>
                </VFlex>

                <VFlex p-24 bdr-24 bc-ww g-24>
                  <Text t-22-600-s8>업체 정보</Text>
                  <VFlex g-16>
                    <KakaoMapContainer
                      company={content?.group?.title || ""}
                      address1={content?.group?.address?.address || ""}
                      address2={""}
                      addressPost={""}
                      tel={content?.group?.contact || ""}
                      designatedCoords={
                        group && group.usedkakoApi === false
                          ? {
                              latitude: group.address.latitude,
                              longitude: group.address.longitude,
                            }
                          : undefined
                      }
                    />
                  </VFlex>
                </VFlex>
                <VFlex p-24 bdr-24 bc-ww g-24>
                  <Text t-22-600-s8>예약자 정보</Text>
                  <VFlex g-16>
                    <VFlex>
                      <Text t-18-600-g3>
                        {content?.subscriber?.nickname
                          ? content?.subscriber?.nickname
                          : content?.subscriber?.name}
                      </Text>
                      <Text t-14-g6>{content?.subscriber?.cellnumber}</Text>
                    </VFlex>
                  </VFlex>
                </VFlex>
                {invoice?.status === "PAID" && (
                  <VFlex
                    f-1
                    p-12-tb
                    p-16-rl
                    c-c
                    bdr-12
                    t-14-600-s8
                    bc-ww
                    clickable
                    onClick={() => {
                      setCancelBottomSheetOpened(true);

                      trackEvent(amplitudeEvents.cancleResClick, {
                        reservationNumber: key,
                      });
                    }}
                  >
                    예약 취소
                  </VFlex>
                )}
              </VFlex>
            </VFlex>
            <VFlex p-24 m-24-t m-32-b g-16>
              <SvgIcon width={75} height={16} icon={SvgIconType.Logo} />
              <Text t-12-g5 style={{ whiteSpace: "break-spaces" }}>
                주식회사 포들러스 | 서비스 이용약관 | 대표자 : 이신혜 |
                02-6953-5788 사업자등록번호: 696-86-02652 | 통신판매업 : 제
                2022-서울성동-01686 호 주소 : 서울특별시 성동구 왕십리로 130,
                10층 (성수동1, KCC 프리미어 타워)
              </Text>
            </VFlex>
          </VFlex>
          {(getStatusString(content?.status) === "이용 완료" ||
            getStatusString(content?.status) === "미방문" ||
            getStatusString(content?.status) === "예약 취소") && (
            <VFlex
              p-16-b
              p-24-trl
              g-8
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1000000,
                background:
                  group?.inquiredReservation !== false ||
                  invoice?.status === "EXPIRED" ||
                  invoice?.status === "CANCELED_REQUEST"
                    ? "linear-gradient(0deg, #FFF 92.08%, rgba(255, 255, 255, 0.00) 100%)"
                    : "none",
              }}
            >
              {(invoice?.status === "EXPIRED" ||
                invoice?.status === "CANCELED_REQUEST") && (
                <VFlex>
                  <HFlex
                    ovf-h
                    p-16
                    g-12
                    a-s
                    bdr-12
                    style={{
                      border: "1px solid rgba(255, 79, 23, 0.30)",
                      background: "rgba(255, 79, 23, 0.10)",
                    }}
                  >
                    <SvgIcon size={24} icon={SvgIconType.Info} />
                    {invoice?.status === "CANCELED_REQUEST" ? (
                      <VFlex g-4>
                        <VFlex t-14-600-s8 l-n>
                          결제 요청이 취소되었습니다.
                        </VFlex>
                        {invoice && (
                          <VFlex t-14-400-s7>
                            결제 취소 일시 :
                            {format(
                              new Date(
                                content.invoiceSummaries.requestCanceled * 1000
                              ),
                              " yy.MM.dd  HH:mm",
                              {
                                locale: ko,
                              }
                            )}
                          </VFlex>
                        )}
                      </VFlex>
                    ) : (
                      <VFlex g-4>
                        <VFlex t-14-600-s8 l-n>
                          기한 만료로 예약이 취소되었습니다.
                        </VFlex>
                        {invoice && (
                          <HFlex g-4 a-c>
                            <VFlex t-14-400-s7>
                              {format(
                                new Date(
                                  content.invoiceSummaries.expiredDate * 1000
                                ),
                                "yy.MM.dd(EEE) a h:mm",
                                {
                                  locale: ko,
                                }
                              )}
                            </VFlex>
                            <Timer
                              style={{
                                color: "#FF4F17",
                              }}
                              time={
                                content.invoiceSummaries.expiredDate * 1000 -
                                new Date().getTime()
                              }
                            />
                          </HFlex>
                        )}
                      </VFlex>
                    )}
                  </HFlex>
                </VFlex>
              )}
              {group?.inquiredReservation !== false && (
                <VFlex
                  p-16-tb
                  p-24-rl
                  c-c
                  bdr-16
                  t-18-ww
                  bc-pt
                  clickable
                  onClick={() => {
                    navigate(`/schedule`, {
                      state: {
                        group: content.group,
                        pet: content.pet,
                        subscriber: content.subscriber,
                        type: "USER_WEB",
                      },
                    });
                  }}
                >
                  재예약 문의하기
                </VFlex>
              )}
            </VFlex>
          )}

          {getStatusString(content?.status) === "예약 확정" && (
            <HFlex
              p-16-b
              p-24-trl
              g-8
              style={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1000000,
                background:
                  "linear-gradient(0deg, #FFF 92.08%, rgba(255, 255, 255, 0.00) 100%)",
              }}
            >
              {getStatusString(content?.status) !== "방문 확정" && (
                <VFlex
                  f-1
                  p-16-tb
                  p-24-rl
                  c-c
                  bdr-16
                  t-18-600-ww
                  bc-pt
                  clickable
                  onClick={() => {
                    setVisitConfirmBottomSheetOpened(true);
                  }}
                >
                  방문 확정
                </VFlex>
              )}
            </HFlex>
          )}

          {content.invoiceSummaries &&
            invoice?.status === "UNPAID" &&
            (getStatusString(content?.status) === "예약 진행중" ||
              getStatusString(content?.status) === "예약 확정") && (
              <VFlex
                p-16-b
                p-24-trl
                g-16
                style={{
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 1000000,
                  background:
                    "linear-gradient(0deg, #FFF 92.08%, rgba(255, 255, 255, 0.00) 100%)",
                }}
              >
                <VFlex>
                  <HFlex
                    ovf-h
                    p-16
                    g-12
                    a-s
                    bdr-12
                    style={{
                      border: "1px solid rgba(255, 195, 94, 0.60)",
                      background: "rgba(255, 195, 94, 0.10)",
                    }}
                  >
                    <SvgIcon size={24} icon={SvgIconType.Info} />
                    <VFlex g-4>
                      <VFlex t-14-600-s8 l-n>
                        {invoice?.type === "RESV_DEPOSIT"
                          ? "기한 내 결제시 예약이 확정됩니다."
                          : "기한 내 결제를 완료해주세요."}
                      </VFlex>

                      <HFlex g-4 a-c>
                        <VFlex t-14-400-s7>
                          {content.invoiceSummaries.expiredDate &&
                            format(
                              new Date(
                                content.invoiceSummaries.expiredDate * 1000
                              ),
                              "yy.MM.dd(EEE) a h:mm",
                              {
                                locale: ko,
                              }
                            )}
                        </VFlex>
                        <Timer
                          style={{
                            color: "#CD9732",
                          }}
                          time={
                            content.invoiceSummaries.expiredDate * 1000 -
                            new Date().getTime()
                          }
                        />
                      </HFlex>
                    </VFlex>
                  </HFlex>
                </VFlex>
                <VFlex
                  p-16-tb
                  p-24-rl
                  c-c
                  bdr-16
                  t-18-ww
                  bc-pt
                  clickable
                  onClick={() => {
                    if (invoice?.type === "RESV_DEPOSIT") {
                      trackEvent(amplitudeEvents.doPayDepositClick, {
                        reservationNumber: key,
                      });
                    } else {
                      trackEvent(amplitudeEvents.doPayClick, {
                        reservationNumber: key,
                      });
                    }

                    navigate(`/invoice?key=${key}`, {
                      state: {},
                    });
                  }}
                >
                  {invoice?.type === "RESV_DEPOSIT"
                    ? "예약금 결제하기"
                    : "결제 진행"}
                </VFlex>
              </VFlex>
            )}

          {content.invoiceSummaries && (
            <CancelBottomSheet
              type={invoice?.type ?? ""}
              open={cancelBottomSheetOpened}
              onDismiss={() => setCancelBottomSheetOpened(false)}
              onComplete={() => {
                setCancelBottomSheetOpened(false);
              }}
            />
          )}

          <VisitConfirmBottomSheet
            open={visitConfirmBottomSheetOpened}
            onDismiss={() => setVisitConfirmBottomSheetOpened(false)}
            onComplete={() => {
              handleConfirmVisit();
              trackEvent(amplitudeEvents.reservationVisitConfirm, {
                reservationNumber: key,
              });
              setVisitConfirmBottomSheetOpened(false);
            }}
          />
        </VFlex>
      )}
    </>
  );
}

const ReservationStatus = ({
  status,
  title,
  questionnaireId,
  history,
  beautyQuestionnaire,
  invoice,
  userCancelNote,
  partnerCancelNote,
  setStageVisible,
}: {
  status: number;
  title: string;
  questionnaireId: number;
  history: any;
  beautyQuestionnaire: boolean;
  invoice: any;
  userCancelNote: string;
  partnerCancelNote: string;
  setStageVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const { trackEvent } = useAmplitudeTrack();

  return (
    <VFlex p-24 bdr-24 bc-ww g-12>
      <HFlex a-c j-b>
        <HFlex g-16 a-c>
          <Image
            d-24
            src={`/image/icon_${
              status === 0
                ? "ongoing"
                : status === 2
                ? "pending"
                : status === 3 || status === 4 || status === 10
                ? "check"
                : "cancel"
            }.png`}
          />
          <Text
            t-22-600
            style={{
              color:
                status === 7 || status === 6 || status === 5 || status === 13
                  ? "#F26312"
                  : "#27BD63",
            }}
          >
            {statusString[status]}
          </Text>
        </HFlex>
        {(status === 2 ||
          status === 0 ||
          status === 3 ||
          status === 10 ||
          status === 4) && (
          <VFlex
            p-12-rl
            p-6-tb
            bc-t2
            bdr-12
            t-14-s6
            onClick={() => {
              setStageVisible(true);
              trackEvent(amplitudeEvents.progressStatus, { status: status });
            }}
          >
            진행현황
          </VFlex>
        )}
      </HFlex>
      {(status === 5 || status === 6 || status === 13) &&
        invoice &&
        invoice.status && (
          <Text t-16-600-s6>
            {invoice.status === "EXPIRED"
              ? "결제 기한 만료"
              : invoice.status === "CANCELED_REQUEST"
              ? "업체의 예약 취소"
              : invoice.status === "U_CANCELED"
              ? "고객의 예약 취소"
              : "예약이 취소되었습니다"}
          </Text>
        )}

      {invoice?.status !== "EXPIRED" && (
        <Text t-16-s4 l-n>
          {status === 0 && `${title}에서 문의를 확인하고 있어요`}
          {status === 2 && "예약금 결제 완료시 예약이 확정됩니다."}
          {status === 3 && "예약이 확정되었습니다."}
          {status === 4 && "이용이 완료되었어요"}
          {(status === 5 || status === 6 || status === 13) &&
            !invoice?.status &&
            "예약이 취소되었습니다"}
          {(status === 5 || status === 6 || status === 13) &&
            (invoice?.status === "CANCELED_REQUEST" ||
              invoice?.status === "U_CANCELED") &&
            `취소사유 : ${partnerCancelNote || userCancelNote}`}
          {status === 10 && `${title} 방문이 확정되었어요`}
          {status === 7 && "미방문 처리되었습니다"}
        </Text>
      )}

      {questionnaireId &&
      (status === 0 || status === 3 || status === 10 || status === 4) &&
      beautyQuestionnaire ? (
        <VFlex
          height={56}
          bdr-16
          bc-gl
          a-c
          j-c
          t-16-s7
          onClick={() => {
            navigate("/questionnaireresult", {
              state: {
                sourceQuestionnaire: history.slice(0, 1).map((item: any) => {
                  return item;
                }),
              },
            });
          }}
        >
          내가 작성한 문진표 보기
        </VFlex>
      ) : null}
    </VFlex>
  );
};
