import { useState } from 'react';

import { BottomSheet } from 'react-spring-bottom-sheet';
import HFlex from '../layouts/HFlex';
import VFlex from '../layouts/VFlex';
import Text from '../layouts/Text';
import Image from '../layouts/Image';

import 'react-spring-bottom-sheet/dist/style.css';

interface PetSpecie {
  id: number;
  name: string;
}

function PetSelectBottomSheet({
  value,
  open,
  onComplete,
}: {
  value?: any[];
  open: boolean;
  onComplete: (item?: any) => void;
}) {
  const [selectedPet, setSelectedPet] = useState<PetSpecie>();

  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-700>
          반려동물 선택
        </Text>
      </HFlex>

      <VFlex p-24-rl p-10-t>
        <Text t-16 c-gd>
          예약을 원하는 반려동물을 선택해주세요.
        </Text>
        <VFlex>
          {value?.map((pet) => {
            return (
              <VFlex key={pet.id}>
                <HFlex
                  a-c
                  j-b
                  style={{
                    margin: '25px 0',
                  }}
                  onClick={() => {
                    setSelectedPet(pet);
                  }}
                >
                  <Text t-18>
                    {pet.name} &#40;{pet.species.name}&#41;
                  </Text>
                  {selectedPet?.id === pet.id ? (
                    <Image d-28 src={`/image/check_on.svg`} />
                  ) : (
                    <Image d-28 src={`/image/check_off.svg`} />
                  )}
                </HFlex>
              </VFlex>
            );
          })}
        </VFlex>
        <VFlex a-st p-24-tb height={112}>
          <VFlex
            f-1
            c-c
            bdr-24
            t-18-ww
            bc-pt
            style={{
              opacity: selectedPet === undefined ? 0.5 : 1,
            }}
            onClick={() => {
              if (selectedPet === undefined) {
                return;
              }
              onComplete?.(selectedPet);
            }}
          >
            확인
          </VFlex>
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default PetSelectBottomSheet;
