import React, { useEffect } from "react";
import { shareKakao } from "../../common/utils/shareKakaoLink";

const LinkKakaotalk = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://developers.kakao.com/sdk/js/kakao.js";
    script.async = true;
    document.body.appendChild(script);

    shareKakao();
    return () => document.body.removeChild(script);
  }, []);

  return <></>;
};

export default LinkKakaotalk;
