import { HTMLAttributes } from "react";

export enum SvgIconType {
  Logo = "logo",
  LogoColor = "logo_color",
  LogoW = "logo_w",
  Back = "back",
  Date = "icon_date",
  Memo = "icon_memo",
  MemoColor = "icon_memo_color",
  Service = "icon_service",
  Staff = "icon_staff",
  Store = "icon_store",
  Time = "icon_time",
  ChevronDown = "chevron_down",
  ChevronRight = "chevron_right",
  CalendarLeft = "calendar_left",
  CalendarRight = "calendar_right",
  Close = "close",
  CloseW = "close_w",
  Questionnaire = "questionnaire",
  QuestionnaireIcon = "questionnaire_icon",
  QuestionnaireNewIcon = "questionnaire_new_icon",
  Check = "check",
  CheckG = "check_g",
  CheckCircle = "check_circle",
  Uncheck = "uncheck",
  ArrowDown = "arrow_down",
  ArrowUp = "arrow_up",
  ArrowRight = "arrow_right",
  ArrowRightBig = "arrow_right_big",
  PaymentComplete = "payment_complete",
  PaymentIncomplete = "payment_incomplete",
  Watermark = "watermark",
  Download = "download",
  Heart = "icon_heart",
  Chat = "icon_chat",
  Smile = "icon_smile",
  Share = "icon_share",
  DownloadB = "icon_download",
  Call = "call",
  AppDown = "app_down",
  AppDownW = "app_down_w",
  RadioOn = "radio_on",
  RadioOff = "radio_off",
  ArrUp = "arr_up",
  ArrDown = "arr_down",
  CompleteCheck = "complete_check",
  CompleteAlert = "complete_alert",
  InvoiceCancel = "invoice_cancel",
  InvoiceDetail = "invoice_detail",
  SafetyNote = "safety_note",
  Speaker = "speaker",
  ServiceNote = "servicenote",
  Send = "send",
  Info = "info",
  AvatarDefault = "avatar_default",
  Avatar = "avatar",
  AddCircle = "add_circle",
  UncheckboxCircle = "uncheckbox_circle",
  CheckboxCircle = "checkbox_circle",
  Success = "success",
  Alert = "alert",
  PawdlyPayB = "pawdlypay_b",
  PawdlyPayW = "pawdlypay_w",
  Deposit = "deposit",
  CreditCard = "credit_card",
  CheckCircleS9 = "check_circle_s9",
  CheckCircleR1 = "check_circle_r1",
  Resize = "resize",
}

const SvgIcon = ({
  icon,
  size,
  width,
  height,
  shadow,
  ...props
}: {
  icon: SvgIconType;
  size?: number;
  width?: number;
  height?: number;
  shadow?: boolean;
} & HTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      style={{
        ...(width || size ? { width: width ?? size } : {}),
        ...(height || size ? { height: height ?? size } : {}),
        ...(shadow
          ? {
              filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
            }
          : {}),
      }}
      src={require(`./${icon}.svg`)}
      alt={`icon_${icon.toString()}`}
      {...props}
    />
  );
};

export default SvgIcon;
