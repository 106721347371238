import KakaoNavi from "../components/KakaoNavi";
import Coparent from "../views/coparent/Coparent";
import Landing from "../views/Landing";
import LinkKakaotalk from "../views/LinkKakaotalk/LinkKakaotalk";
import LinkReservation from "../views/LinkReservation/LinkReservation";
import LinkReservationStep from "../views/LinkReservation/LinkReservationStep";
import Invoice from "../views/invoice/Invoice";
import InvoiceCancel from "../views/invoice/InvoiceCancel";
import InvoiceComplete from "../views/invoice/InvoiceComplete";
import InvoiceDetail from "../views/invoice/InvoiceDetail";
import Reservation from "../views/payment/Reservation";
import Questionnaire from "../views/Questionnaire/Questionnaire";
import QuestionnaireResult from "../views/Questionnaire/QuestionnaireResult";
import ReservationNotePhotos from "../views/ReservationNotePhotos";
import Schedule from "../views/Schedule";
import ServiceNote from "../views/ServiceNote";
import ServiceNotePhotos from "../views/ServiceNotePhotos";
import ServiceNoteReview from "../views/ServiceNoteReview";
import Payment from "../views/invoice/payment";
import InvoiceReceipt from "../views/invoice/InvoiceReceipt";

const routes = [
  {
    path: "",
    children: [{ path: "", element: <Landing /> }],
  },
  {
    path: "schedule",
    children: [{ path: "", element: <Schedule /> }],
  },
  {
    path: "servicenote",
    children: [{ path: "", element: <ServiceNote /> }],
  },
  {
    path: "servicenotephotos",
    children: [{ path: "", element: <ServiceNotePhotos /> }],
  },
  {
    path: "servicenotereview",
    children: [{ path: "", element: <ServiceNoteReview /> }],
  },
  {
    path: "questionnaire",
    children: [{ path: "", element: <Questionnaire /> }],
  },
  {
    path: "questionnaireresult",
    children: [{ path: "", element: <QuestionnaireResult /> }],
  },
  {
    path: "reservation",
    children: [{ path: "", element: <Reservation /> }],
  },
  {
    path: "reservationnotephotos",
    children: [{ path: "", element: <ReservationNotePhotos /> }],
  },
  {
    path: "kakaonavi",
    children: [{ path: "", element: <KakaoNavi /> }],
  },
  {
    path: "linkreservation",
    children: [{ path: "", element: <LinkReservation /> }],
  },
  {
    path: "linkreservationstep",
    children: [{ path: "", element: <LinkReservationStep /> }],
  },
  {
    path: "linkkakaotalk",
    children: [{ path: "", element: <LinkKakaotalk /> }],
  },
  {
    path: "coparent",
    children: [{ path: "", element: <Coparent /> }],
  },
  {
    path: "invoice",
    children: [{ path: "", element: <Invoice /> }],
  },
  {
    path: "invoicedetail",
    children: [{ path: "", element: <InvoiceDetail /> }],
  },
  {
    path: "invoicecomplete",
    children: [{ path: "", element: <InvoiceComplete /> }],
  },
  {
    path: "invoicecancel",
    children: [{ path: "", element: <InvoiceCancel /> }],
  },
  {
    path: "payment",
    children: [{ path: "", element: <Payment /> }],
  },
  {
    path: "invoicereceipt",
    children: [{ path: "", element: <InvoiceReceipt /> }],
  },
];

export default routes;
