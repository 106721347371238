import { InputHTMLAttributes, useRef, useState } from "react";

import Absolute from "./Absolute";
import HFlex from "./HFlex";
import Text from "./Text";
import VFlex from "./VFlex";

import SvgIcon, { SvgIconType } from "../svg";

function tryParseLocalize(str: string) {
  if (/^[0-9.,]+$/.test(str)) {
    try {
      str = str.slice(0, 20).replaceAll(",", "");
      if (str.endsWith(".")) {
        const i = str.indexOf(".");
        return parseFloat(str).toLocaleString() + (i === -1 ? "" : ".");
      }
      return parseFloat(str).toLocaleString();
    } catch {}
  }
  return str;
}

function TextInput({
  placeholder,
  isNumber,
  caption,
  required,
  disabled,
  error,
  value,
  onChangeValue,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  placeholder?: string;
  isNumber?: boolean;
  caption?: string;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  value: string | undefined;
  onChangeValue?: (value: string) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [cancelMouseDown, setCancelMouseDown] = useState<boolean>(false);
  return (
    <VFlex
      g-8
      unclickable={disabled}
      clickable={!disabled}
      style={{ cursor: "text" }}
    >
      {caption && (
        <HFlex g-4>
          <Text t-14-gd>{caption}</Text>
          {required && <Text t-12-bb>*</Text>}
        </HFlex>
      )}
      <VFlex
        bc-t1
        bd-t3={!error && !focused}
        bd-gr5={!error && focused}
        bd-err1={error}
        rel
        bdr-16
        height={56}
        onClick={() => {
          setFocused(true);
          inputRef.current?.focus();
        }}
      >
        {(!!caption ? !value : !!placeholder) && (
          <HFlex f-1 p-24-rl a-c>
            <Text
              t-16-s1={!!caption || !(focused || !!value)}
              m-22-b={!caption && (focused || !!value)}
              t-12-s1={!caption && (focused || !!value)}
              transition
            >
              {placeholder}
            </Text>
          </HFlex>
        )}
        <Absolute>
          <HFlex
            a-c
            p-24-rl
            style={{ opacity: focused || caption || !!value ? 1 : 0 }}
            height={"100%"}
          >
            <input
              ref={inputRef}
              style={{
                flex: 1,
                backgroundColor: "transparent",
                borderWidth: 0,
                padding: 0,
                height: 22,
                color: disabled ? "#979E9A" : "#252726",
                marginTop: !caption && !!placeholder ? 16 : 0,
                fontSize: !caption && !!placeholder ? 14 : 16,
              }}
              value={isNumber ? tryParseLocalize(value ?? "") : value ?? ""}
              onChange={(e) =>
                onChangeValue?.(
                  (isNumber
                    ? tryParseLocalize(e.target.value)
                    : e.target.value) || ""
                )
              }
              {...props}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            {!!value && (focused || cancelMouseDown) && (
              <HFlex
                c-c
                onClick={(e) => {
                  onChangeValue?.("");
                  e.stopPropagation();
                  setCancelMouseDown(false);
                }}
                onMouseDown={() => setCancelMouseDown(true)}
              >
                <SvgIcon icon={SvgIconType.Close} size={24} />
              </HFlex>
            )}
          </HFlex>
        </Absolute>
      </VFlex>
    </VFlex>
  );
}

export default TextInput;
