import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import KakaoMap from "./KakaoMap";

import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import HFlex from "../layouts/HFlex";

function KakaoMapContainer({
  company,
  address1,
  address2,
  addressPost,
  tel,
  designatedCoords,
}: {
  company: string;
  address1: string;
  address2: string;
  addressPost: string;
  tel: string;
  designatedCoords?: {
    latitude: number;
    longitude: number;
  };
}) {
  const navigate = useNavigate();
  const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(
        `${
          text.includes("key") || text.includes("revNo") ? "링크" : "주소"
        }가 복사되었습니다.`
      );
    } catch (e) {
      toast.error("복사에 실패하였습니다. 다시 시도해주세요.");
    }
  };

  const handleClickKakaoNavi = (address: string) => {
    navigate("/kakaonavi", { state: { address: address } });
  };

  return (
    <VFlex g-16>
      <VFlex>
        <Text t-18-600-s6>{company}</Text>
        <Text t-14-g6 l-n>
          {address1}
        </Text>
      </VFlex>
      {address1 ? (
        <KakaoMap address={address1} designatedCoords={designatedCoords} />
      ) : (
        <Text>위치 정보 없음</Text>
      )}

      <HFlex g-8 height={54}>
        <VFlex
          f-1
          c-c
          t-14-500-g6
          bdr-16
          bc-g9
          onClick={() => {
            handleClickKakaoNavi(address1);
          }}
        >
          카카오 내비
        </VFlex>
        <VFlex
          f-1
          c-c
          t-14-500-g6
          bdr-16
          bc-g9
          onClick={() => {
            handleCopyClipBoard(address1);
          }}
        >
          주소 복사
        </VFlex>
      </HFlex>
      <HFlex g-8 height={54}>
        <VFlex
          f-1
          c-c
          t-14-500-g6
          bdr-16
          bc-g9
          onClick={() => {
            handleCopyClipBoard(window.location.href);
          }}
        >
          공유하기
        </VFlex>
        <VFlex
          f-1
          c-c
          t-14-500-g6
          bdr-16
          bc-g9
          onClick={() => {
            window.location.href = `tel://${tel}`;
          }}
        >
          전화하기
        </VFlex>
      </HFlex>
    </VFlex>
  );
}

export default KakaoMapContainer;
