import { BottomSheet } from "react-spring-bottom-sheet";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import SvgIcon, { SvgIconType } from "../svg";

import "react-spring-bottom-sheet/dist/style.css";
import "swiper/css";

function QuestionnaireDoneBottomSheet({
  open,
  onDismiss,
  onComplete,
}: {
  open: boolean;
  onDismiss: () => void;
  onComplete: () => void;
}) {
  return (
    <BottomSheet
      className="bottomsheet"
      open={open}
      initialFocusRef={false}
      expandOnContentDrag={true}
      onDismiss={onDismiss}
    >
      <HFlex a-c p-24-rl height={44}>
        <Text f-1 t-22-600>
          안전 문진표를 다 작성했어요
        </Text>
        <SvgIcon
          size={24}
          icon={SvgIconType.Close}
          onClick={() => onDismiss()}
        />
      </HFlex>
      <VFlex p-24-trl g-16>
        <Text f-1 t-16>
          안전 문진표를 등록할까요?
        </Text>
        <VFlex a-st p-24-tb height={112}>
          <VFlex
            f-1
            c-c
            bdr-24
            t-18-ww
            bc-pt
            onClick={() => {
              onComplete();
            }}
          >
            등록하기
          </VFlex>
        </VFlex>
      </VFlex>
    </BottomSheet>
  );
}

export default QuestionnaireDoneBottomSheet;
